<template>
<div class="pick-img">
    <div class="single-img" v-for="(item, index) in fileUrl" :key="index">
        <div class="delete-img" @click="deleteImge(index)">
            <van-icon name="cross" color="#fff" />
        </div>
        <img style="width:2.5rem;height:2rem;object-fit: cover;" :src="item" alt="add" @click="previewImg(index)" />
    </div>
    <div class="place-img" @click="showAction" v-if="fileUrl.length < 8">
        <van-icon name="plus" color="#bbb" size="1rem" />
    </div>
</div>
</template>

<script>
import axios from 'axios';
import wx from 'weixin-js-sdk';
import {
    getWxLocation
} from '@/utils/utils';
export default {
    props: {
        imageMaxCount: {
            type: Number,
            default: 8
        },
        proveData: {
            type: Array,
            default: null
        },
        symbol: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        proveData: {
            handler(images) {
                this.fileUrl = images;
            },
            immediate: true
        },
    },
    data() {
        return {
            // fileList: [],
            fileUrl: [],
            location: ''
        };
    },
    async mounted() {
        const loc = await getWxLocation();
        this.location = `${loc.longitude},${loc.latitude}`;
        window.console.log('地理位置', this.location);
    },
    created() {
        // this.setWxConfig();
    },

    methods: {
        // 删除图片
        deleteImge(index) {
            this.fileUrl.splice(index, 1);
            console.info("当前选中的图片 --> " + this.fileUrl[index]);
            //通知父组件
            if (this.symbol) {
                if (confirm("确认删除图片吗？") === true) {
                    axios
                        .post(`${process.env.VUE_APP_FSS_BASE_URL}/file/delete`, {
                            url: this.fileUrl[index],
                            apiSecretKey: "xjqsh@123"
                        })
                        .then(res => {
                            if (res && res.data && res.data.success) {
                                this.fileUrl.splice(index, 1);
                                console.info("当前选中的图片 --> " + this.fileUrl[index]);
                                //通知父组件
                                this.$emit("changed", this.fileUrl);
                            }
                        })
                        .catch(err => {
                            this.$toast.fail("删除图片失败！" + err);
                        });
                }
            } else {
                this.$emit("changed", this.fileUrl);
            }

        },

        // 选择图片上传
        showAction() {
            let imgCount = 8 - this.fileUrl.length;
            if (imgCount <= 0) {
                this.$toast({
                    message: `最多只能上传${this.imageMaxCount}个图片`
                });
                return;
            }

            wx.chooseImage({
                count: imgCount,
                sizeType: ['original', 'compressed'],
                sourceType: ['camera', 'album'],
                success: async res => {
                    let localIds = res.localIds;
                    let imageList = [];
                    // 异步变同步
                    for (let i = 0; i < localIds.length; i++) {
                        let localId = localIds[i];
                        let data = await this.getDisplayData(localId);
                        imageList.push(data);
                    }
                    this.afterRead(imageList);

                },
                fail: (err) => {
                    window.console.log(err);
                    this.$toast({
                        type: 'fail',
                        message: '请重试……'
                    });
                    this.setWxConfig();
                }
            });
        },

        // 预览
        previewImg(index) {
            // window.console.log('previewImg','文件--->', this.fileList, 'url->', this.fileUrl);
            wx.previewImage({
                current: this.fileUrl[index],
                urls: this.fileUrl, // 需要预览的图片http链接列表
                fail: (err) => {
                    window.console.log(err);
                    this.$toast({
                        type: 'fail',
                        message: '请重试……'
                    });
                    this.setWxConfig();
                }
            });
        },

        // Promise 封装异步请求
        getDisplayData(localId) {
            return new Promise(function (resolve) {
                wx.getLocalImgData({
                    localId: localId, // 图片的localID
                    success: response => {
                        var localData = response.localData; // localData是图片的base64数据，可以用img标签显示
                        if (localData.indexOf('data:image') != 0) {
                            //判断是否有这样的头部
                            localData = 'data:image/jpeg;base64,' + localData;
                        }
                        localData = localData
                            .replace(/\r|\n/g, '')
                            .replace('data:image/jgp', 'data:image/jpeg');
                        resolve(localData);
                    }
                });
            });
        },

        // base64转data
        convertBase64UrlToBlob(urlData) {
            var bytes = window.atob(urlData.split(",")[1]); //去掉url的头，并转换为byte
            var type = urlData.split("/")[1].split(";")[0];
            //处理异常,将ascii码小于0的转换为大于0
            var ab = new ArrayBuffer(bytes.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < bytes.length; i++) {
                ia[i] = bytes.charCodeAt(i);
            }

            return this.getBlob([ab], type);
        },

        getBlob(buffer, format) {
            try {
                return new Blob(buffer, {
                    type: format
                });
            } catch (e) {
                var bb = new(window.BlobBuilder ||
                    window.WebKitBlobBuilder ||
                    window.MSBlobBuilder)();
                buffer.forEach(function (buf) {
                    bb.append(buf);
                });
                return bb.getBlob(format);
            }
        },

        afterRead(file) {
            //组装参数
            let formData = new FormData();
            //文件流
            file.forEach(el => {
                let blob = this.convertBase64UrlToBlob(el);
                let fileName = this.uuid() + ".jpeg";
                formData.append("files", blob, fileName);
            });
            //项目名称
            formData.append("application", 'trace-mobile');
            //文件类型
            formData.append("type", 'image');
            //秘钥
            formData.append("apiSecretKey", 'xjqsh@123');
            formData.append("location", this.location);

            axios.post(`${process.env.VUE_APP_FSS_BASE_URL}/file/upload`, formData, {
                //上传图片需要花费很长的时间。超时定为10分钟。
                timeout: 10 * 60 * 1000,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(res => {
                    if (res && res.data && res.data.success) {
                        // window.console.log("上传照成功后给fileUrl添加数据之前："+this.fileUrl.join(';'));
                        this.$toast.success("上传成功");
                        window.console.log("上传照成功后返回的数据url：" + JSON.stringify(res.data.url));
                        //res.data.url 数组类型
                        if (res.data.url && res.data.url.length > 0) {
                            this.fileUrl = this.fileUrl.concat(res.data.url);
                        }
                        // this.fileUrl.push(res.data.url);
                        window.console.log("上传照成功后给fileUrl添加数据之后：" + JSON.stringify(this.fileUrl));
                        this.$emit("changed", this.fileUrl);
                    }

                    // eslint-disable-next-line no-unused-vars
                }).catch(err => {
                    this.$toast.fail('上传图片失败！' + err);
                });
        },

        uuid() {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
                c
            ) {
                var r = (Math.random() * 16) | 0,
                    v = c == "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        }
    },
};
</script>

<style lang="less" scoped>
.pick-img {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
}

.place-img {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #bbb;
    width: 2.5rem;
    height: 2rem;
}

/*自定义 通过微信选择图片上传*/
.single-img {
    position: relative;
    margin-right: 15px;
}

.delete-img {
    position: absolute;
    top: -10px;
    right: -15px;
    background-color: #bbb;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 88;
}
</style>
