<template>
<div>
    <van-cell-group>
        <van-field v-model="name" label="姓名" maxlength="200" placeholder="请输入姓名" style="text-align: left" />
        <van-field v-model="call" type="tel" maxlength="11" label="电话" placeholder="请输入联系电话" style="text-align: left" />
        <van-field v-model="shopCode" readonly label="肉店" placeholder="请选择备案的肉店经营代码" style="text-align: left" is-link @click="chooseCode" />
    </van-cell-group>
    <div class="msg">
        <span>列表不存在选择的肉店,</span>
        <router-link to="/me/addShop">新增肉店备案</router-link>
    </div>
    <!-- 保存按钮 -->
    <div class="button-view">
        <van-button @click="submitCheck" style="width:85%;" round color="linear-gradient(to right, #20c854, #7edf39)">保存</van-button>
    </div>
</div>
</template>

<script>
import localStorage from '../utils/storage';
import https from '../utils/request';
import {phoneValidate} from '../utils/utils';
export default {
    data() {
        return {
            name: '',
            call: '',
            shopCode: '',
            userId: localStorage.get('userId')
        };
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.path !== '/me/allShops') {
                vm.getUserInfo();
            } else {
                let info = vm.$store.state.info;
                vm.name = info.name;
                vm.call = info.call;
                vm.shopCode = info.code;
            }
        });
    },

    mounted() {
        // hidden tabbar
        // this.hideTabbar();
    },

    beforeDestroy() {
        // this.showTabbar();
    },

    methods: {
        // 选择肉店列表
        chooseCode() {
            this.$store.commit('SET_INFO', {
                name: this.name,
                call: this.call,
                code: this.shopCode
            });
            this.$router.push({
                path: '/me/allShops',
            });
        },

        getUserInfo() {
            // eslint-disable-next-line no-empty
            if (this.userId) {
                let url = '/api/users';
                let params = {
                    id: this.userId
                };
                https.get(url, {
                    params
                })
                    .then(res => {
                        this.$toast.clear();
                        if (res.status === 200 && res.data && res.data.data) {
                            let item = res.data.data[0];
                            this.name = item.fullname;
                            this.call = item.mobile;
                            if (item.shop) {
                                this.shopCode = item.shop.code;
                            }
                        }
                    })
                    .catch(err => {
                        this.$toast.clear();
                        if (err.status !== 401) {
                            this.$toast({
                                type: 'fail',
                                message: '服务器异常, 请重试！'
                            });
                        }
                    });
            }

        },

        // 提交
        submitCheck() {
            if (!this.shopCode) {
                this.$toast.fail("请选择肉店经营代码！！");
                return;
            }
            if(!(phoneValidate(this.call))) {
                this.$toast.fail("请填写正确的手机号");
                return;  
            }
            if (this.name && this.shopCode) {
                this.$toast.loading({
                    duration: 0,
                    message: '数据上传中...',
                    forbidClick: true,
                    loadingType: 'spinner'
                });
                let url = '/api/users';

                https.post(url, JSON.stringify({
                    "data": {
                        "0": {
                            id: this.userId,
                            fullname: this.name,
                            mobile: this.call,
                            shopCode: this.shopCode,
                        }
                    }
                }))
                    .then(res => {
                        this.$toast.clear();
                        if (res.data) {
                            if (res.data.fieldErrors) {
                                this.$toast({
                                    type: 'fail',
                                    message: res.data.fieldErrors[0].status
                                });
                                return;
                            }
                            this.$toast.success('保存成功！');
                            // 提交成功跳回首页
                            let timer = setTimeout(() => {
                                this.$router.replace({
                                    path: '/me'
                                });
                                clearTimeout(timer);
                            }, 2000);
                        }
                    }).catch(err => {
                        this.$toast.clear();
                        if (err.status !== 401) {
                            this.$toast.fail('保存失败, 请重试！' + err);
                        }
                    });
            } else {
                this.$toast.fail("请完善个人信息！");
            }
        }
    }
};
</script>

<style lang="less" scoped>
.msg {
    margin-top: 50px;

    & span {
        color: @gray;
    }

    & a {
        color: @mainface;
    }
}

.button-view {
    text-align: center;
    margin-top: 100px;
}

// vant css
.van-cell {
    font-size: @font-36px  !important;
    line-height: 70px !important;
    background-color: rgba(255, 255, 255, 0);
}
</style>
