import Vue from 'vue';
import Router from 'vue-router';
import Check from '../views/Check.vue';
import localStorage from '@/utils/storage.js';
import RecordDetail from '@/views/RecordDetail.vue';
import SnapRing from'@/views/SnapRing.vue';
import Arrive from '@/views/Arrive.vue';
import Records from '@/views/Record.vue';
import Detail from '@/views/Detail.vue';
import PaaeRecords from '@/views/PaaeRecords.vue';
import  Me from'@/views/Me.vue';
import  UserInfo from '@/views/UserInfo.vue';
import  NewShop from '@/views/NewShop.vue';
import ShopMannage  from'@/views/ShopMannage.vue';
import Author from'@/views/Author.vue';
import CheckList from '@/views/CheckList.vue';
import CheckDetail from '@/views/CheckDetail.vue';
import HangTicket from '@/views/HangTicket.vue';
import ShopList from  '@/views/ShopList.vue';
import RelateTicket from '@/views/RelateTicket.vue';

import GXSnapring from'@/views/GXSnapring.vue';
import HTSnapring from'@/views/HTSnapring.vue';


Vue.use(Router);
let vue = new Vue();

const routerConfig = [
    {
        path: '/',
        redirect: '/check',
        meta: {
            requireAuth: true,
            roles: [] // you can set roles in root nav
        }
    },
    {
        path: '/check',
        name: 'check',
        component: Check,
        meta: {
            roles: [],
            requireAuth: true,
            title: '到店'
        }
    },
    {
        path: '/arrive',
        name: 'arrive',
        component: Arrive,
        meta: {
            roles: [],
            requireAuth: true,
            title: '到店确认'
        }
    },
    {
        path: '/records',
        name: 'records',
        component: Records,
        meta: {
            roles: [],
            requireAuth: true,
            title: '记录'
        }
    },
    {
        path: '/records/detail',
        name: 'detail',
        component: Detail,
        meta: {
            roles: [],
            requireAuth: true,
            title: '记录详情'
        }
    },
    {
        path: '/records/newDetail',
        name: 'newDetail',
        component: RecordDetail,
        meta: {
            roles: [],
            title: '记录详情'
        }
    },
    {
        path: '/paaeRecords',
        name: 'paaeRecords',
        component: PaaeRecords,
        meta: {
            roles: [],
            requireAuth: true,
            title: '防疫记录'
        }
    },
    {
        path: '/me',
        name: 'me',
        component: Me,
        meta: {
            roles: [],
            requireAuth: true,
            title: '我的'
        }
    },
    {
        path: '/me/info',
        name: 'info',
        component: UserInfo,
        meta: {
            roles: [],
            requireAuth: true,
            title: '个人信息'
        }
    },
    {
        path: '/me/addShop',
        name: 'newshop',
        component: NewShop,
        meta: {
            roles: [],
            requireAuth: true,
            title: '新增肉店备案'
        }
    },
    {
        path: '/me/shopList',
        name: 'shops',
        component: ShopMannage,
        meta: {
            roles: [],
            requireAuth: true,
            title: '肉店管理'
        }
    },
    {
        path: '/me/bills',
        name: 'bills',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Bills.vue'),
        meta: {
            roles: [],
            requireAuth: true,
            title: '我的账单'
        }
    },
    {
        path: '/me/billDetail',
        name: 'billdetail',
        component: () => import(/* webpackChunkName: "about" */ '@/views/BillDetail.vue'),
        meta: {
            roles: [],
            requireAuth: true,
            title: '账单详情'
        }
    },
    {
        path: '/author',
        name: 'author',
        component: Author
    },
    {
        path:'/relateTicket',
        component: RelateTicket,
        name: 'relate',
        meta: {
            roles: [],
            title: '关联票-详情'
        }
    },
    {
        path:'/checkList',
        component: CheckList,
        meta: {
            roles: [],
            title: '待审核列表'
        }
    },
    {
        path:'/checkDetail',
        component: CheckDetail,
        meta: {
            roles: [],
            title: '待审核详情'
        }
    },
    {
        path:'/snapring',
        component: SnapRing,
        meta: {
            roles: [],
            title: '追溯'
        }
    },
    
    {
        path:'/gxsnapring',
        component: GXSnapring,
        meta: {
            roles: [],
            title: '追溯'
        }
    },
    {
        path:'/htsnapring',
        component: HTSnapring,
        meta: {
            roles: [],
            title: '追溯'
        }
    },
    {
        path:'/trace-fail',
        component: () => import(/*webpackPrefetch: true,*/ '@/views/TraceFail.vue'),
        meta: {
            roles: [],
            title: '追溯'
        }
    },
    {
        path:'/me/allShops',
        component: ShopList,
        meta: {
            roles: [],
            title: '已验证店铺列表'
        }
    },
    {
        path:'/hangTicket',
        component: HangTicket,
        meta: {
            roles: [],
            title: '扫码挂票'
        }
    },
];

const router = new Router({   
    routes: routerConfig
});
router.beforeEach((to, from, next) => {
    to.query.vt = new Date().getTime().toString();
    console.log('beforeEach==>', to.query);
    document.title = to.meta.title;
    if (to.meta.requireAuth) {
        if (JSON.parse(localStorage.get('userId'))) {
        // eslint-disable-next-line no-constant-condition
        // if (false) {
            let rolesString = localStorage.get('roles');
            let userRoles = [];
            if (rolesString) {
                userRoles = JSON.parse(rolesString);
            }
            if (!userRoles) {
                userRoles = [];
            }
            let roles = to.meta.roles;
            if (roles.length === 0) {
                next();
            } else {
                let hasRoles;
                hasRoles = roles.filter(role => userRoles.includes(role));
                if (hasRoles.length > 0) {
                    next();
                } else {
                    vue.$notify({
                        message: '您当前没有该权限，请联系管理员申请该权限!',
                        type: 'warning',
                        center: true
                    });
                }
            }
        } else {
            let redirect = to.path;
            localStorage.set('redirect', redirect);
            if (navigator.userAgent.toLowerCase().includes('MicroMessenger'.toLowerCase()) && process.env.NODE_ENV !== 'development') {
                let callback = encodeURIComponent('https://trace-mobile.xjy0.cn/#/author');
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx837fb1a89e11bbc8&redirect_uri=${callback}&response_type=code&scope=snsapi_userinfo&state=${redirect}#wechat_redirect`;
            } else {
                next();
            }
        }
    } else {
        next();
    }
});

router.onError(error => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);

    if (isChunkLoadFailed) {
        location.reload();
    }
});
export default router;
