<template>
<div class="detail">
    <!-- <van-sticky :offset-top="0"> -->
        <div class="bg-top">
            <div class="shop">
                <div class="shop-name">{{shop.name}}</div>
                <div class="shop-address">
                    <van-icon name="location" />
                    <span>{{shop.address}}</span>
                </div>
                <div>
                    <span class="shop-info">经营代码</span>
                    <span>{{shop.code}}</span>
                </div>
                <div>
                    <span class="shop-info">经营法人</span>
                    <span>{{shop.corp}}</span>
                </div>
            </div>
        </div>
    <!-- </van-sticky> -->

    <!-- 动物票 产品票 -->
    <div class="ticket">
        <div class="section">产品票信息</div>
        <div>
            <PronductA v-if="productTicketType === 'productA'" :proveData="ticketInfo"></PronductA>
            <PronductB v-if="productTicketType === 'productB'" :proveData="ticketInfo"></PronductB>
        </div>
    </div>
</div>
</template>

<script>
import PronductA from '../components/Product-A';
import PronductB from '../components/Product-B';
import axios from 'axios';
import https from '../utils/request';
export default {
    components: {
        PronductA,
        PronductB
    },
    data() {
        return {
            ticketInfo: {},
            aninmalTicket: {},
            shop: ''
        };
    },

    created() {
        this.hideTabbar();
    },

    beforeDestroy() {
        this.showTabbar();
    },

    computed: {
        productTicketType() {
            if (this.ticketInfo && this.ticketInfo.type) {
                return "product" + this.ticketInfo.type;
            } else {
                return '';
            }
        }
    },

    mounted() {
        // hidden tabbar
        this.hideTabbar();
        // 票的详情
        let number = this.$route.query.number;
        if(number){
            this.getQRToTicket(number);
        }
        let ticket = this.$route.query.ticket;
        let type = this.$route.query.type;
        let shopInfo=this.$route.query.shop;
        if(shopInfo){
            this.shop = JSON.parse(this.$route.query.shop);
        }
        // 1 先查产品票信息
        if(ticket && type === 'product') {
            this.getTicketInfo(ticket);
        }

    },
    methods: {
        getQRToTicket(number){
            this.$toast.loading({
                duration: 0,
                message: '数据加载中...',
                forbidClick: true,
                loadingType: 'spinner'
            });
            https.get(`/api/hangTicket/getTicketAndShop?number=${number}`)
                .then(res => {
                    this.$toast.clear();
                    
                    console.info(JSON.stringify(res));
                    if (res.data && res.data.success === true && res.data.data) {
                        let result = res.data.data;
                        console.info(JSON.stringify(result));
                        this.shop = result.shop;
                        this.getTicketInfo(result.ticket); 
                    } else {
                        let message=res.message;
                        this.$toast.fail('异常, 请重试！' + message);
                    }
                })
                .catch(err => {
                    this.$toast.clear();
                    if (err.status !== 401) {
                        this.$toast.fail('异常, 请重试！' + err);
                    }
                });
        },
        
        getTicketInfo(code) {
            this.$toast.loading({
                duration: 0,
                message: '数据加载中...',
                forbidClick: true,
                loadingType: 'spinner'
            });
            console.log("开票地址："+process.env.VUE_APP_TICKET_BASE_URL);
            axios.post(`${process.env.VUE_APP_TICKET_BASE_URL}/api/tickets/getProductTicket`, {
                ticket: code,
                type: 'product'
            })
                .then(res => {
                    this.$toast.clear();
                    window.console.log('查出的票-----', res, res.data.data);
                    if (res.data.data && res.data.data.length != 0) {
                        let data = res.data.data;
                        data.startRegion = `${data.startProvince.name}${data.startCity.name ? data.startCity.name:''}${data.startCounty.name ? data.startCounty.name :''}${data.startTown.name? data.startTown.name: ''}${data.startPlace ? data.startPlace: ''}`;
                        data.endRegion = `${data.endProvince.name}${data.endCity.name ? data.endCity.name:''}${data.endCounty.name ? data.endCounty.name :''}${data.endTown?( data.endTown.name? data.endTown.name: '') : ''}${data.endPlace ? data.endPlace: ''}`;
                        let ticketInfo = {};
                        ticketInfo = data;
                        ticketInfo.ticketType = data.animal.name;
                        if (data.product.parent.label) {
                            ticketInfo.ticketType = data.animal.name + '/' + data.product.parent.label + '/' + data.product.label;
                        } else {
                            ticketInfo.ticketType = data.animal.name + '/' + data.product.label;
                        }
                        if (data.startCompany) {
                            ticketInfo.startRegion = data.startRegion + `${data.startCompany.name? data.startCompany.name: ''}`;
                        }
                        this.ticketInfo = ticketInfo;
                        window.console.log('处理的票-----', ticketInfo);
                    } else {
                        this.$toast.fail('查无此票,无效的检疫证号!');
                    }
                })
                .catch(err => {
                    this.$toast.clear();
                    if (err.status !== 401) {
                        this.$toast.fail('查询异常, 请重试！' + err);
                    }
                });
        }
    },
};
</script>

<style lang="less" scoped>
.detail {
    padding-bottom: 120px;
}

.bg-top {
    height: 380px;
    background: url('https://cdn.xjy0.cn/ticket/trace/detail_top.png') no-repeat top;
    background-origin: content-box;
    background-clip: content-box;
    background-size: 100vw 380px;
    position: relative;
}

.shop {
    background-color: #fff;
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: -100px;
    border-radius: 20px;
    padding: 20px 30px 20px;
    text-align: left;
    color: @black;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);

    & .shop-name {
        color: @mainface;
        margin-bottom: 10px;
    }

    & .shop-address {
        font-size: @font-28px;
        color: @lightgray;
        margin-bottom: 10px;
    }

    & .shop-info {
        color: @gray;
        margin-right: 20px;

    }
}

.ticket {
    position: absolute;
    left: 25px;
    right: 25px;
    top: 540px;
    background-color: #fff;

    & .section {
        text-align: left;
        padding: 10px;
        border-left: 8px solid @mainface;
    }
}
</style>
