<template>
  <div class="detail">
    <div class="bg-top">
      <!-- top图 -->
      <van-image
        style="margin-bottom: 10px"
        width="100vw"
        height="260"
        :src="
          'https://cdn.xjy0.cn/ticket/trace/trace-snapring/' +
          regionPic +
          '.png'
        "
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
      <!-- <img class="top-img" style="width: 100vw;height: 220px;" :src="'https://cdn.xjy0.cn/ticket/trace/trace-snapring/' + regionPic +'.png'"> -->
    </div>
    <!-- 卡环编号 -->
    <div class="snap-view">
      <div class="snap">追溯编码:</div>
      <div class="code">{{ snapring }}</div>
    </div>
    <!-- 标签 -->
    <van-tabs
      v-model="active"
      scrollspy
      sticky
      v-if="productShow"
      color="#20c854"
      line-height="5px"
      title-active-color="#31af01"
      title-inactive-color="#333"
    >
      <van-tab title="核酸检测">
        <div class="ticket">
          <NAT v-if="traceNAT" :result="traceNAT" />
        </div>
      </van-tab>
      <van-tab title="屠宰检疫">
        <div class="ticket">
          <Product class="product" v-if="ticketInfo" :proveData="ticketInfo" />
        </div>
      </van-tab>
      <van-tab title="产地检疫">
        <div class="ticket">
          <Animal
            v-if="childTicketType === 'animal' && aninmalTicket"
            :proveData="aninmalTicket"
          />
        </div>
      </van-tab>
      <van-tab title="畜主信息" v-if="aninmalTicket.ownerIds && aninmalTicket.ownerIds.length">
        <div class="ticket">
          <Owner
            :ownerId="aninmalTicket.ownerIds"
            :category="aninmalTicket.ticketType"
          ></Owner>
        </div>
      </van-tab>
    </van-tabs>

    <div v-else class="box">
      <blanket></blanket>
      <span class="no-form">暂无追溯信息</span>
    </div>
    <div class="logo">
      <img
        class="company"
        src="//cdn.xjy0.cn/trace-mini/image/1530070637.png"
        alt="company"
      />
    </div>
  </div>
</template>

<script>
import Product from "../components/Product";
import Animal from "../components/Animal";
import axios from "axios";
import https from "../utils/request";
import { REQUEST_TYPE } from "../assets/constant/index";
import Owner from "../components/Owner";
import Blanket from "../components/BlanketHolder";
import url from "url";
import queryString from "querystring";
import PictureMixin from "@/mixins/PictureMixin.js";
import NAT from "@/components/NAT";
export default {
    components: {
        Product,
        Animal,
        Owner,
        Blanket,
        NAT,
    },
    mixins: [PictureMixin],
    data() {
        return {
            ticketInfo: {},
            aninmalTicket: {},
            childTicketType: "",
            snapring: "",
            regionPic: "",
            active: 0,
            traceNAT:''
        };
    },

    created() {},
    async mounted() {
        this.hideTabbar();
        let ring = url.parse(location.href).query;
        let query = queryString.parse(ring);
        if (query && query.ht) {
            this.snapring = query.ht;
            // 1 先查产品票信息
            if (this.snapring) {
                const res = await this.traceDetail(this.snapring);
                if (res && res !== -1) {
                    this.traceNAT = res;
                    const product = await this.getProductTicketInfo(res.ticket);
                    console.log(product);
                } else {
                    this.traceNAT = '';
                    this.regionPic = "65";
                }
            }
        } else {
            this.regionPic = "65";
        }
        // hidden tabbar
        this.hideTabbar();
        // 票的详情
        console.log("regionmixin----->", this.regionCode);
    },

    beforeDestroy() {
        this.showTabbar();
    },

    computed: {
        productTicketType() {
            if (this.ticketInfo && this.ticketInfo.type) {
                return "product" + this.ticketInfo.type;
            } else {
                return "";
            }
        },
        productShow() {
            let keys = Object.keys(this.ticketInfo);
            if (keys && keys.length > 0) {
                return true;
            } else {
                return false;
            }
        },
    },

    methods: {
    /**
     * 扫追溯码查票
     * @param {number} 追溯标签/二维码 编码0000000002
     */
        traceDetail(param) {
            this.$toast.loading({
                duration: 0,
                message: "数据加载中...",
                forbidClick: true,
                loadingType: "spinner",
            });
            return axios
                .get(`${process.env.VUE_APP_HT_BASE_URL}/api/ncovHangTicket/getNcov?number=${param}`)
                .then((res) => {
                    this.$toast.clear();
                    if (res.data.fieldErrors) {
                        this.$toast.fail(`查询失败${res.data.fieldErrors[0].status}`);
                        return -1;
                    }
                    if (res.data && res.data.success) {
                        return res.data.data;
                    } else {
                        return -1;
                    }
                })
                .catch((err) => {
                    this.$toast.clear();
                    if (err.status !== 401) {
                        this.$toast.fail(`追溯详情查询失败${err.status}`);
                    }
                });
        },
        getProductTicketInfo(code) {
            this.$toast.loading({
                duration: 0,
                message: "数据加载中...",
                forbidClick: true,
                loadingType: "spinner",
            });
            console.log("开票地址：" + process.env.VUE_APP_TICKET_BASE_URL);
            axios
                .post('https://ticket-mobile.xjy0.cn/api/tickets/getProductTicket', {
                    ticket: code,
                    type: 'product'
                })
                .then((res) => {
                    this.$toast.clear();
                    // window.console.log('查出的票-----', res, res.data.data);
                    if (res.data.data && res.data.data.length != 0) {
                        let data = res.data.data;
                        // region图片
                        let startRegion = data.startRegion.toString();
                        if (startRegion && startRegion.length >= 6) {
                            let sixRegion = startRegion.slice(0, 6);
                            console.log("region判断--->", startRegion, sixRegion);
                            this.regionPic = this.getRegionMapPhoto(sixRegion);
                        } else {
                            this.regionPic = "65";
                        }
                        data.startRegion = `${data.startProvince.name}${
                            data.startCity.name ? data.startCity.name : ""
                        }${data.startCounty.name ? data.startCounty.name : ""}${
                            data.startTown.name ? data.startTown.name : ""
                        }${data.startPlace ? data.startPlace : ""}`;
                        data.endRegion = `${data.endProvince.name}${
                            data.endCity.name ? data.endCity.name : ""
                        }${data.endCounty.name ? data.endCounty.name : ""}${
                            data.endTown ? (data.endTown.name ? data.endTown.name : "") : ""
                        }${data.endPlace ? data.endPlace : ""}`;
                        let ticketInfo = {};
                        ticketInfo = data;
                        ticketInfo.ticketType = data.animal.name;
                        if (data.product.parent.label) {
                            ticketInfo.ticketType =
                data.animal.name +
                "/" +
                data.product.parent.label +
                "/" +
                data.product.label;
                        } else {
                            ticketInfo.ticketType =
                data.animal.name + "/" + data.product.label;
                        }
                        if (data.startCompany) {
                            ticketInfo.startRegion =
                data.startRegion +
                `${data.startCompany.name ? data.startCompany.name : ""}`;
                        }
                        ticketInfo.numUnit = data.number + data.unit;
                        this.ticketInfo = ticketInfo;

                        this.toRelateTicket(ticketInfo);
                        //
                        window.console.log("处理的票-----", ticketInfo);
                    } else {
                        this.regionPic = "65";
                    }
                })
                .catch((err) => {
                    this.regionPic = "65";
                    this.$toast.clear();
                    if (err.status !== 401) {
                        this.$toast.fail("查询异常, 请重试！" + err);
                    }
                });
        },

        toRelateTicket(ticket) {
            let union = ticket.animalTicket;
            let code = "";
            if (union !== null && union) {
                /* eslint-disable */
        let unionList = union.split(/[, ；，; 、.\/]/);
        code = unionList[0];
      }
      if (ticket.quarantineType && code) {
        let request = REQUEST_TYPE[ticket.quarantineType];
        if (ticket.quarantineType.includes("动物")) {
          this.childTicketType = "animal";
          this.getAnimalTicketInfo(code, this.childTicketType, request);
        } else if (ticket.quarantineType.includes("产品")) {
          this.childTicketType = "product";
        }
      }
    },

    getAnimalTicketInfo(code, type, request) {
      this.$toast.loading({
        duration: 0,
        message: "数据加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      axios
        .post(`${process.env.VUE_APP_TICKET_BASE_URL}/api/tickets/` + request, {
          ticket: code,
          type: type,
        })
        .then((res) => {
          this.$toast.clear();
          if (res.data.data && res.data.data.length != 0) {
            let data = res.data.data;
            data.startRegion = `${data.startProvince.name}${
              data.startCity.name ? data.startCity.name : ""
            }${data.startCounty.name ? data.startCounty.name : ""}${
              data.startTown.name ? data.startTown.name : ""
            }${data.startPlace ? data.startPlace : ""}`;
            data.endRegion = `${data.endProvince.name}${
              data.endCity.name ? data.endCity.name : ""
            }${data.endCounty.name ? data.endCounty.name : ""}${
              data.endTown ? (data.endTown.name ? data.endTown.name : "") : ""
            }${data.endPlace ? data.endPlace : ""}`;
            let aninmalTicket = {};
            aninmalTicket = data;
            if (type === "animal") {
              aninmalTicket.ticketType = data.animal.name;
            } else {
              if (data.product.parent.label) {
                aninmalTicket.ticketType =
                  data.animal.name +
                  "/" +
                  data.product.parent.label +
                  "/" +
                  data.product.label;
              } else {
                aninmalTicket.ticketType =
                  data.animal.name + "/" + data.product.label;
              }
              if (data.startCompany) {
                aninmalTicket.startRegion =
                  data.startRegion +
                  data.startRegion +
                  `${data.startCompany.name ? data.startCompany.name : ""}`;
              }
            }
            aninmalTicket.stationList = res.data.eventResults;
            aninmalTicket.numberUnit = data.number + data.unit;
            this.aninmalTicket = aninmalTicket;
          } else {
            this.aninmalTicket = "";
          }
          window.console.log("animal", this.aninmalTicket);
        })
        .catch((err) => {
          this.$toast.clear();
          window.console.log(err);
          if (err.status !== 401) {
            this.$toast.fail("异常, 请重试！" + err);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  padding-bottom: 20px;
  height: 100%;

  /deep/ .van-tab {
    font-size: @font-34px;
  }
  /deep/ .van-tabs--line .van-tabs__wrap {
    height: 110px;
  }
}

.logo {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}

.top-img {
  margin-bottom: 20px;
}

.company {
  width: 50%;
  height: 100px;
  object-fit: contain;
}

.product {
  margin-bottom: 20px;
}

.ticket {
  margin: 25px;
  overflow-x: hidden;
  overflow-y: hidden;

  & .section {
    text-align: left;
    padding: 10px;
    border-left: 8px solid @mainface;
  }
}

.snap-view {
  background-color: #fff;
  margin: 0 25px 20px;
  padding: 20px 25px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  text-align: left;

  .snap {
    position: relative;
    padding-left: 20px;
    color: #31af01;
  }

  .code {
    margin-left: 20px;
    font-size: @font-36px;
    color: #666;
  }
}

.snap::before {
    display: block;
    content: "";
    width: 9px;
    height: 30px;
    background: linear-gradient(0, #20c854, #7cdf3a);
    position: absolute;
    top: 7px;
    left: 0;
    border-radius: 3px;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
}

.no-form {
  font-size: @font-28px;
  color: #666;
}
</style>
<style lang="less">
