<template>
  <div id="app">
    <router-view />
    <div style="margin-top:20px">
      <van-tabbar
        id="tabbar"
        safe-area-inset-bottom
        route
        active-color="#666"
        inactive-color="#000"
        v-model="active"
      >
        <van-tabbar-item replace to="/check">
          <span>到店</span>
          <img
            slot="icon"
            class="tab-img"
            slot-scope="props"
            :src="props.active ? checkIcon.active : checkIcon.inactive"
          />
        </van-tabbar-item>
        <van-tabbar-item replace to="/records">
          <span>记录</span>
          <img
            slot="icon"
            class="tab-img"
            slot-scope="props"
            :src="props.active ? recordsIcon.active : recordsIcon.inactive"
          />
        </van-tabbar-item>
        <van-tabbar-item replace to="/me">
          <span>账户</span>
          <img
            slot="icon"
            class="tab-img"
            slot-scope="props"
            :src="props.active ? meIcon.active : meIcon.inactive"
          />
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import localStorage from "./utils/storage";
import { getDate } from "./utils/utils";
export default {
    data() {
        return {
            active: 0,
            checkIcon: {
                active: require("./assets/image/check-active.png"),
                inactive: require("./assets/image/check.png"),
            },
            recordsIcon: {
                active: require("./assets/image/record-active.png"),
                inactive: require("./assets/image/record.png"),
            },
            meIcon: {
                active: require("./assets/image/me-active.png"),
                inactive: require("./assets/image/me.png"),
            },
        };
    },
    mounted() {
    // 刷新操作
        window.addEventListener("beforeunload", this.saveState);
        this.$store.commit("SET_DATE", getDate());
    },

    created() {
        let state = JSON.parse(localStorage.getSession("vuexState"));
        if (state) {
            this.$store.replaceState(state);
        }
    },

    methods: {
        saveState() {
            localStorage.setSession("vuexState", JSON.stringify(this.$store.state));
        },
    },
};
</script>

<style lang="less" >
#app,
html,
body {
  height: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  box-sizing: border-box;
  font-size: @font-34px;
  background-color: @backcolor;
}
#app {
  /deep/ .van-tab {
    height: 120px;
  }
  /deep/ .van-tabbar-item {
    font-size: @tabbar-font;
  }
}

.tab-img {
  width: 56px;
  height: 56px;
  object-fit: contain;
}
</style>
