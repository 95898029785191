<template>
  <div class="detail">
    <div v-if="shopShow || productShow">
      <div class="bg-top" v-if="shopShow">
        <!-- 轮播图 -->
        <swiper height="4.5rem" :list="banners" effect="normal"></swiper>
        <div class="shop">
          <div class="shop-name">{{shop.name}}</div>
          <div class="shop-address">
            <van-icon name="location" />
            <span>{{shop.address}}</span>
          </div>
          <div>
            <span class="shop-info">经营代码</span>
            <span class="shop-info">{{shop.code}}</span>
          </div>
          <div>
            <span class="shop-info">经营法人</span>
            <span class="shop-info">{{shop.corp}}</span>
          </div>
        </div>
      </div>

      <!-- 动物票 产品票 -->
      <div class="ticket" v-if="productShow">
        <div class="date-view">
          <div class="show-time">
            <div class="dot"></div>
            <div>到店时间:</div>
            <div class="time">{{arriveDate}}</div>
          </div>
          <div class="show-time sell" v-if="sellDate">
            <div class="dot"></div>
            <div>销售时间:</div>
            <div class="time">{{sellDate}}</div>
          </div>
        </div>

        <!-- 验证码验证 -->
        <div class="validate-view" v-if="traceNumber">
          <van-field
            v-model="validate"
            center
            clearable
            type="number"
            label-width="110px"
            style="text-align:left"
            maxlength="4"
            label="追溯验证码"
            placeholder="请输入4位验证码"
          >
            <template #button>
              <van-button
                style="margin-left: 15px"
                type="primary"
                size="mini"
                @click="validateCode"
              >校验</van-button>
            </template>
          </van-field>
        </div>

        <!-- 验证弹出 -->
        <van-popup v-model="show">
          <img
            v-if="isValidate"
            class="dialog-img"
            src="https://cdn.xjy0.cn/ticket/trace/success.png"
          />
          <img v-else class="dialog-img" src="https://cdn.xjy0.cn/ticket/trace/fail.png" />
          <div class="delete" @click="show=false"></div>
        </van-popup>

        <!-- <Product class="product" v-if="ticketInfo" :proveData="ticketInfo" />
        <Animal v-if="childTicketType === 'animal' && aninmalTicket" :proveData="aninmalTicket" />
        <Owner
          v-if="aninmalTicket.ownerIds && aninmalTicket.ownerIds.length"
          :ownerId="aninmalTicket.ownerIds"
          :category="aninmalTicket.ticketType"
        ></Owner> -->
        <!-- 标签 -->
        <van-tabs
          v-model="active"
          scrollspy
          sticky
          v-if="productShow"
          color="#20c854"
          line-height="5px"
          title-active-color="#31af01"
          title-inactive-color="#333"
        >
          <van-tab title="屠宰检疫">
            <div class="ticket">
              <Product class="product" v-if="ticketInfo" :proveData="ticketInfo" />
            </div>
          </van-tab>
          <van-tab title="产地检疫">
            <div class="ticket">
              <Animal
                v-if="childTicketType === 'animal' && aninmalTicket"
                :proveData="aninmalTicket"
              />
            </div>
          </van-tab>
          <van-tab title="畜主信息">
            <div class="ticket">
              <Owner
                v-if="aninmalTicket.ownerIds && aninmalTicket.ownerIds.length"
                :ownerId="aninmalTicket.ownerIds"
                :category="aninmalTicket.paaeCategory"
              ></Owner>
            </div>
          </van-tab>
        </van-tabs>
      </div>
      <blanket v-else style="margin-top:50px"></blanket>
    </div>
    <blanket v-else style="margin-top:150px"></blanket>
  </div>
</template>

<script>
import Product from "../components/Product";
import Animal from "../components/Animal";
import axios from "axios";
import https from "../utils/request";
import { REQUEST_TYPE } from "../assets/constant/index";
import Owner from "../components/Owner";
import swiper from "../components/Swiper";
import Blanket from "../components/BlanketHolder";
export default {
    components: {
        Product,
        Animal,
        Owner,
        swiper,
        Blanket,
    },
    data() {
        return {
            active: 0,
            ticketInfo: {},
            aninmalTicket: {},
            shop: "",
            childTicketType: "",
            // 到店日期
            arriveDate: "",
            // 销售日期
            sellDate: "",
            // 图片
            banners: [],
            // 追溯验证码
            validate: "",
            // 追溯码
            traceNumber: "",
            show: false,
            isValidate: true,
        };
    },

    created() {
        this.hideTabbar();
    },

    beforeDestroy() {
        this.showTabbar();
    },

    computed: {
        productTicketType() {
            if (this.ticketInfo && this.ticketInfo.type) {
                return "product" + this.ticketInfo.type;
            } else {
                return "";
            }
        },
        productShow() {
            let keys = Object.keys(this.ticketInfo);
            if (keys && keys.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        shopShow() {
            let keys = Object.keys(this.shop);
            if (keys && keys.length > 0) {
                return true;
            } else {
                return false;
            }
        },
    // photoList() {
    //     if(this.banners && this.banners.length > 0) {
    //         return this.banners;
    //     } else {
    //         return [this.src];
    //     }
    // }
    },

    mounted() {
    // hidden tabbar
        this.hideTabbar();
        // 票的详情
        let number = this.$route.query.number;
        if (number) {
            this.traceNumber = number;
            this.getQRToTicket(number);
        }
        let ticket = this.$route.query.ticket;
        let type = this.$route.query.type;
        let shopInfo = this.$store.state.recordShop;
        if (shopInfo) {
            this.shop = shopInfo;
            this.arriveDate = this.shop.arriveDate;
            this.banners = this.shop.photos;
        }
        // 1 先查产品票信息
        if (ticket && type === "product") {
            this.getProductTicketInfo(ticket);
        }
    },
    methods: {
    // 校验追溯验证码
        validateCode() {
            if (this.traceNumber && this.validate && this.validate.length === 4) {
                axios
                    .get(
                        `${process.env.VUE_APP_CHANGECODE_BASE_URL}/productNumber/validateVerificationCode`,
                        {
                            params: {
                                code: this.validate,
                                number: this.traceNumber,
                            },
                        }
                    )
                    .then((res) => {
                        this.show = true;
                        console.log(res.data);
                        if (res.data) {
                            this.isValidate = res.data.success;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.$toast.fail("请输入4位的验证码");
            }
        },
        getQRToTicket(number) {
            this.$toast.loading({
                duration: 0,
                message: "数据加载中...",
                forbidClick: true,
                loadingType: "spinner",
            });
            https
                .get(`/api/hangTicket/getTicketAndShop?number=${number}`)
                .then((res) => {
                    this.$toast.clear();
                    if (res.data && res.data.success === true && res.data.data) {
                        let result = res.data.data;
                        // console.info(JSON.stringify(result));
                        this.shop = result.shop;
                        this.banners = this.shop.photos;
                        this.sellDate = result.hangTicketDate;
                        this.arriveDate = result.arriveDate;
                        this.getProductTicketInfo(result.ticket);
                    } else {
                        this.ticketInfo = {};
                    }
                })
                .catch((err) => {
                    this.$toast.clear();
                    if (err.status !== 401) {
                        this.$toast.fail("查询异常, 请重试！");
                    }
                });
        },
        getProductTicketInfo(code) {
            this.$toast.loading({
                duration: 0,
                message: "数据加载中...",
                forbidClick: true,
                loadingType: "spinner",
            });
            console.log("开票地址：" + process.env.VUE_APP_TICKET_BASE_URL);
            axios
                .post(
                    `${process.env.VUE_APP_TICKET_BASE_URL}/api/tickets/getProductTicket`,
                    {
                        ticket: code,
                        type: "product",
                    }
                )
                .then((res) => {
                    this.$toast.clear();
                    // window.console.log('查出的票-----', res, res.data.data);
                    if (res.data.data && res.data.data.length != 0) {
                        let data = res.data.data;
                        data.startRegion = `${data.startProvince.name}${
                            data.startCity.name ? data.startCity.name : ""
                        }${data.startCounty.name ? data.startCounty.name : ""}${
                            data.startTown.name ? data.startTown.name : ""
                        }${data.startPlace ? data.startPlace : ""}`;
                        data.endRegion = `${data.endProvince.name}${
                            data.endCity.name ? data.endCity.name : ""
                        }${data.endCounty.name ? data.endCounty.name : ""}${
                            data.endTown ? (data.endTown.name ? data.endTown.name : "") : ""
                        }${data.endPlace ? data.endPlace : ""}`;
                        let ticketInfo = {};
                        ticketInfo = data;
                        ticketInfo.ticketType = data.animal.name;
                        if (data.product.parent.label) {
                            ticketInfo.ticketType =
                data.animal.name +
                "/" +
                data.product.parent.label +
                "/" +
                data.product.label;
                        } else {
                            ticketInfo.ticketType =
                data.animal.name + "/" + data.product.label;
                        }
                        if (data.startCompany) {
                            ticketInfo.startRegion =
                data.startRegion +
                `${data.startCompany.name ? data.startCompany.name : ""}`;
                        }
                        ticketInfo.numUnit = data.number + data.unit;
                        this.ticketInfo = ticketInfo;

                        this.toRelateTicket(ticketInfo);
                        // window.console.log('处理的票-----', ticketInfo);
                    }
                })
                .catch((err) => {
                    this.$toast.clear();
                    if (err.status !== 401) {
                        this.$toast.fail("查询异常, 请重试！" + err);
                    }
                });
        },
        toRelateTicket(ticket) {
            let union = ticket.animalTicket;
            let code = "";
            if (union !== null && union) {
                /* eslint-disable */
        let unionList = union.split(/[, ；，; 、.\/]/);
        code = unionList[0];
      }
      if (ticket.quarantineType && code) {
        let request = REQUEST_TYPE[ticket.quarantineType];
        if (ticket.quarantineType.includes("动物")) {
          this.childTicketType = "animal";
          this.getAnimalTicketInfo(code, this.childTicketType, request);
        } else if (ticket.quarantineType.includes("产品")) {
          this.childTicketType = "product";
        }
      }
    },
    getAnimalTicketInfo(code, type, request) {
      this.$toast.loading({
        duration: 0,
        message: "数据加载中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      axios
        .post(`${process.env.VUE_APP_TICKET_BASE_URL}/api/tickets/` + request, {
          ticket: code,
          type: type,
        })
        .then((res) => {
          this.$toast.clear();
          if (res.data.data && res.data.data.length != 0) {
            let data = res.data.data;
            data.startRegion = `${data.startProvince.name}${
              data.startCity.name ? data.startCity.name : ""
            }${data.startCounty.name ? data.startCounty.name : ""}${
              data.startTown.name ? data.startTown.name : ""
            }${data.startPlace ? data.startPlace : ""}`;
            data.endRegion = `${data.endProvince.name}${
              data.endCity.name ? data.endCity.name : ""
            }${data.endCounty.name ? data.endCounty.name : ""}${
              data.endTown ? (data.endTown.name ? data.endTown.name : "") : ""
            }${data.endPlace ? data.endPlace : ""}`;
            let aninmalTicket = {};
            aninmalTicket = data;
            if (type === "animal") {
              aninmalTicket.ticketType = data.animal.name;
              aninmalTicket.paaeCategory = data.animal.parentName;
            } else {
              if (data.product.parent.label) {
                aninmalTicket.ticketType =
                  data.animal.name +
                  "/" +
                  data.product.parent.label +
                  "/" +
                  data.product.label;
              } else {
                aninmalTicket.ticketType =
                  data.animal.name + "/" + data.product.label;
              }
              if (data.startCompany) {
                aninmalTicket.startRegion =
                  data.startRegion +
                  data.startRegion +
                  `${data.startCompany.name ? data.startCompany.name : ""}`;
              }
            }
            aninmalTicket.stationList = res.data.eventResults;
            aninmalTicket.numberUnit = data.number + data.unit;
            this.aninmalTicket = aninmalTicket;
          } else {
            this.aninmalTicket = "";
          }
          window.console.log("animal", this.aninmalTicket);
        })
        .catch((err) => {
          this.$toast.clear();
          window.console.log(err);
          if (err.status !== 401) {
            this.$toast.fail("异常, 请重试！" + err);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  padding-bottom: 20px;
}

.shop {
  background-color: #fff;
  margin: 0 25px;
  margin-top: 20px;
  border-radius: 20px;
  padding: 20px 30px 20px;
  text-align: left;
  color: @black;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to right, #20c854, #7edf39);

  & .shop-name {
    color: #fff;
    margin-bottom: 10px;
  }

  & .shop-address {
    font-size: @font-28px;
    color: #666;
    margin-bottom: 10px;
  }

  & .shop-info {
    color: #fff;
    margin-right: 20px;
  }
}

.date-view {
  margin: 20px 0;
  padding: 30px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 20px;
  text-align: left;

  .show-time {
    display: flex;
    align-items: center;

    .dot {
      width: 15px;
      height: 15px;
      background-color: #ddd;
      border-radius: 50%;
      margin-right: 15px;
    }

    .time {
      margin-left: 20px;
      color: orange;
    }

    .sell {
      margin-top: 20px;
    }
  }
}

.product {
  margin-bottom: 20px;
}

.ticket {
  margin: 25px;
  overflow-x: hidden;
  overflow-y: hidden;
  // padding-bottom: 20px;

  & .section {
    text-align: left;
    padding: 10px;
    border-left: 8px solid @mainface;
  }
}

.validate-view {
  // padding: 20px 30px  0 30px;
  padding-bottom: 20px;
}
.dialog-img {
  width: 85vw;
  position: relative;
}
.delete {
  width: 90px;
  height: 90px;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  right: 0;
}
// vant css
.van-cell {
  font-size: @font-34px !important;
  color: #666 !important;
  border-radius: 10px !important;
  padding: 20px 15px !important;
}
.van-button {
  height: 60px !important;
  font-size: @font-34px !important;
}
.van-popup {
  background-color: rgba(0, 0, 0, 0);
}

.van-field__control {
  margin: 0 0 0 30px !important;
}
 /deep/ .van-tab {
      font-size: @font-34px;
  }
  /deep/ .van-tabs--line .van-tabs__wrap {
      height: 110px;
  }
</style>
