let cookie = {
    set(key, value, expiredays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + expiredays);
        document.cookie = key + '=' + escape(value) +
            ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString());
    },
    get(key) {
        if (document.cookie.length > 0) {
            let start = document.cookie.indexOf(key + '=');
            if (start !== -1) {
                start = start + key.length + 1;
                let end = document.cookie.indexOf(';', start);
                if (end === -1) {
                    end = document.cookie.length;
                }
                return unescape(document.cookie.substring(start, end));
            }
        }
        return '';
    }
};

export default cookie;
