<template>
<div class="container">
    <div class="title">待审核肉店信息</div>
    <!-- 轮播图 -->
    <div class="swipe-loop">
        <!-- 轮播 -->
        <van-swipe class="swipe-view" :autoplay="6000" ref="swiper" @change="onChange">
            <van-swipe-item v-for="(image, index) in banners" :key="index">
                <img class="swipe-img" v-lazy="image" />
            </van-swipe-item>
            <!-- tool-bar -->
            <div slot="indicator" class="bottom-view">
                <i class="magnify"><img src="../assets/image/magnify.png" alt="" @click="preViewImag"></i>
                <div class="custom-indicator">
                    <div>{{ current + 1 }}/{{banners.length}}</div>
                </div>
            </div>
            <div slot="indicator" class="change-photo">
                <i><img src="../assets/image/left.png" alt="" @click="changeImageView('up')"></i>
                <i><img src="../assets/image/right.png" alt="" @click="changeImageView('down')"></i>
            </div>
        </van-swipe>
    </div>
    <!-- shop-detail -->
    <div class="shop-info">
        <div class="shop-name">
            {{shop.name}}
        </div>
        <div>
            <van-field v-model="shop.code" readonly label="经营代码:" label-width=90px style="text-align: left;color: #999" :border="false" />
            <van-field v-model="shop.corp" readonly label="经营法人:" label-width=90px style="text-align: left;color: #999" :border="false" />
            <van-field v-model="shop.phone" readonly label="联系电话:" label-width=90px style="text-align: left;color: #999" :border="false" />
            <van-field v-model="shop.address" readonly label="详细地址:" type="textarea" label-width=90px rows="1" autosize maxlength="50" style="text-align: left;color: #999" :border="false" />
        </div>
    </div>
    <div class="shop-info">
        <div class="shop-name check">
            审核
        </div>
        <div>
            <div class="check-buttons" v-if="dispose">
                <van-button round type="info" color="#22c853" @click="resolve('已通过')">通过</van-button>
                <van-button round color="#fe6600" @click="show=true">拒绝</van-button>
            </div>
            <div class="chek-tip" v-else>
                {{tip}}
            </div>
        </div>
    </div>
    <van-popup v-model="show">
        <div class="pop-dialog">
            <div class="dialog-title">驳回理由</div>
            <div class="dialog-content">
                <van-field v-model="message" rows="2" autosize type="textarea" maxlength="50" placeholder="请填写驳回理由" show-word-limit />
            </div>
            <div class="bottom">
                <van-button round type="info" color="#22c853" @click="resolve('未通过')">确定</van-button>
            </div>
        </div>
    </van-popup>
</div>
</template>

<script>
import wx from 'weixin-js-sdk';
import Vue from 'vue';
import localStorage from '../utils/storage';
import {
    Lazyload
} from 'vant';
Vue.use(Lazyload);
export default {
    data() {
        return {
            banners: [],
            current: 0,
            shop: {},
            show: false,
            message: '',
            dispose: false,
            tip: '',
            shopId: ''
        };
    },
    mounted() {
        // hidden tabbar
        // this.hideTabbar();
        let code = this.$route.query.id;
        if (code) {
            this.shopId = code;
            this.getShopInfo();
        }
    },

    beforeDestroy() {
        // this.showTabbar();
    },
    methods: {
        // 审核
        resolve(result) {
            console.log(result, this.message);
            if (result === '未通过' && !this.message) {
                this.$toast.fail('请填写拒绝理由！');
                return;
            }
            let verify = localStorage.get('userId');
            if (!verify) {
                this.$toast.fail('无法获取到审核人信息！');
                return;
            }
            this.$toast.loading({
                duration: 0,
                message: '数据上传中...',
                forbidClick: true,
                loadingType: 'spinner'
            });
            this.$https.post('/api/shop/setStatus', 
                {
                    id: this.shop.id, //肉店Id
                    verifyId: verify, // 审核人用户Id
                    status: result, // 审核状态
                    reason: this.message // 拒绝原因，只有拒绝的时候才会填写
                })
                .then(res => {
                    this.show = false;
                    this.$toast.clear();
                    if (res.status === 200 && res.data) {
                        if (res.data.success) {
                            this.$toast.success('审核已处理！');
                        } else {
                            this.$toast.fail('审核失败');
                        }
                        this.getShopInfo();
                        let timer = setTimeout(() => {
                            this.$router.go(-1);
                            clearTimeout(timer);
                        }, 2000);
                    }
                })
                .catch(err => {
                    this.show = false;
                    this.$toast.clear();
                    if (err.status !== 401) {
                        this.$toast.fail('异常, 请重试！' + err);
                    }
                });

        },
        // 预览
        preViewImag() {
            wx.previewImage({
                current: this.banners[this.current],
                urls: this.banners, // 需要预览的图片http链接列表
                fail: (err) => {
                    window.console.log(err);
                    this.$toast({
                        type: 'fail',
                        message: '请重试……'
                    });
                    this.setWxConfig();
                }
            });
        },
        changeImageView(type) {
            if (type == 'up') {
                if (this.current > 0) {
                    this.current--;
                }
            } else if (type == 'down') {
                if (this.current < this.banners.length - 1) {
                    this.current++;
                }
            }
            this.$refs.swiper.swipeTo(this.current);
        },
        onChange(index) {
            this.current = index;
        },
        getShopInfo() {
            this.$https.get('/api/shops', {
                params: {
                    id: this.shopId
                }
            })
                .then(res => {
                    console.log('getCkeckList', res.data);
                    if (res.data && res.data.data.length > 0) {
                        this.shop = res.data.data[0];
                        this.banners = this.shop.photos.split(";");
                        if (this.shop.status === '未处理') {
                            this.dispose = true;
                        } else {
                            this.dispose = false;
                            if (this.shop.status === '未通过') {
                                this.tip = `当前店铺未通过审核，理由：${this.shop.reason}`;
                            }
                            if (this.shop.status === '已通过') {
                                this.tip = '当前店铺已通过审核';
                            }
                        }
                    }
                })
                .catch(err => {
                    console.log('catch', err);
                });
        }
    },
};
</script>

<style lang="less" scoped>
.container {
    padding-bottom: 180px;
    .title {
        text-align: left;
        color: #999;
        padding: 20px;
    }

    .swipe-view {
        height: 334px;
        margin: 0 20px;
        border-radius: 20px;

        .bottom-view {
            // background-color: red;
            position: absolute;
            right: 20px;
            bottom: 20px;
            display: flex;

            .magnify,
            .custom-indicator {
                padding: 5px 25px;
                background: rgba(255, 255, 255, 0.3);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
                margin-right: 10px;

                img {
                    width: 100%;
                }
            }

            .custom-indicator {
                color: #333;
                font-size: 38px;
            }

        }

        .change-photo {
            position: absolute;
            right: 20px;
            top: 35%;
            width: 95%;
            display: flex;
            justify-content: space-between;

        }

        .swipe-img {
            width: 100%;
            height: 334px;
            object-fit: fit;
        }
    }

    .shop-info {
        margin: 25px 20px;
        padding: 10px;
        background-color: #fff;
        border-radius: 20px;

        .shop-name {
            font-weight: 600;
            text-align: left;
            padding: 10px 25px;
            font-size: 38px;
            position: relative;
        }

        .shop-name::after {
            content: " ";
            position: absolute;
            pointer-events: none;
            box-sizing: border-box;
            -webkit-transform-origin: center;
            transform-origin: center;
            top: auto;
            left: 15px;
            right: 0;
            bottom: 0;
            -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
            border-bottom: 2px dashed #ddd;
        }
    }

    .check {
        color: #999;
        font-size: 36px !important;
        font-weight: 100 !important;
    }

    .check-buttons {
        padding: 30px;
        display: flex;
        justify-content: space-between;
    }

    .pop-dialog {
        width: 85vw;
        height: 40vh;
        background-color: #fff;

        .dialog-title {
            padding: 20px;
        }

        .dialog-content {
            height: 20vh;
            border-top: 2px solid #ddd;
            border-bottom: 2px solid #ddd;
        }

        .bottom {
            padding: 30px;
        }
    }
}

.chek-tip {
    padding: 50px;
    color: orange;
}

.van-cell {
    flex: 0.5 !important;
    font-size: @font-32px  !important;
}

/deep/.van-button {
    width: 220px;
    height: 80px;
    text-align: center;
}

/deep/.van-popup--center {
    border-radius: 20px;
}
</style>
