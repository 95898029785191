import { render, staticRenderFns } from "./ShopList.vue?vue&type=template&id=767c66d4&scoped=true"
import script from "./ShopList.vue?vue&type=script&lang=js"
export * from "./ShopList.vue?vue&type=script&lang=js"
import style0 from "./ShopList.vue?vue&type=style&index=0&id=767c66d4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "767c66d4",
  null
  
)

export default component.exports