import { render, staticRenderFns } from "./Animal-A.vue?vue&type=template&id=82e436e4&scoped=true"
import script from "./Animal-A.vue?vue&type=script&lang=js"
export * from "./Animal-A.vue?vue&type=script&lang=js"
import style0 from "./Animal-A.vue?vue&type=style&index=0&id=82e436e4&prod&lang=less&scoped=true"
import style1 from "../assets/css/style.css?vue&type=style&index=1&id=82e436e4&prod&scoped=scoped&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82e436e4",
  null
  
)

export default component.exports