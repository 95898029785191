
let storage = {
    set(key, value) {
        localStorage.setItem(key, value);
    },
    get(key) {
        return localStorage.getItem(key);
    },
    remove(key) {
        localStorage.removeItem(key);
    },
    removeAll() {
        localStorage.clear();
        sessionStorage.clear();
    },
    // session
    setSession(key, value) {
        sessionStorage.setItem(key, value);
    },
    getSession(key) {
        return sessionStorage.getItem(key);
    },
    removeSession(key) {
        sessionStorage.removeItem(key);
    }
};

export default storage;
