import moment from 'moment/moment';
export function getDate(date) {
    let formatDate = date
        ? new Date(date)
        : new Date();
    const t1 = moment(formatDate)
        .format('YYYY-MM-DD');
    return `${t1}`;
}
// function formatNumber (n) {     const str = n.toString();     return str[1] ?
// str : `0${str}`;   }
export function getDateTime(date) {
    let formatDate = date
        ? new Date(date)
        : new Date();
    const last = moment(formatDate)
        .format('YYYY-MM-DD HH:mm:ss')
        .toString();
    return last;
}

export function getTime() {
    let formatDate = new Date();
    let transTime = moment(formatDate)
        .format('HH:mm:ss')
        .toString();
    return transTime;
}
export function compareDate(start, end) {
    if (start && end) {
        const startDate = start
            ? new Date(start)
            : new Date();
        const endDate = end
            ? new Date(end)
            : new Date();
        let timeInterval = (endDate.getTime() - startDate.getTime()) > 0
            ? true
            : false;
        return timeInterval;
    } else {
        return true;
    }
}

// 手机号码验证
export function phoneValidate(phone) {
    const regex = /^1[3456789]\d{9}$/;
    return regex.test(phone);
}

// 数字验证
export function numberValidate(number) {
    const regex = /^[0-9]\d*$/;
    return regex.test(number);
}

// 字符串为空的判断
export function emptyString(string) {
    return string.length;
}

// 手机号*号占位替换
export function getTel(tel) {
    var reg = /^(\d{3})\d{4}(\d{4})$/;
    return tel.replace(reg, '$1****$2');
}

import wx from 'weixin-js-sdk';
export function getWxLocation() {
    return new Promise((resolve, reject) => {
        wx.getLocation({
            type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            success: (res) => {
                var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                resolve({
                    latitude,
                    longitude
                });
            },
            fail: () => {
                reject();
            }
        });
    });
}