import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'amfe-flexible';
import base from './utils/base';
import request from './utils/request';
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(base);

// 添加原型属性-请求挂载到原型
Vue.prototype.$https = request;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
