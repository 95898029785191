<template>
<div class="container" style="padding: 0 10px;">
    <!-- 查出对应的证 -->
    <div>
        <AninmalA v-if="ticketType === 'animalA'" :proveData="ticketInfo"></AninmalA>
        <AninmalB v-if="ticketType === 'animalB'" :proveData="ticketInfo"></AninmalB>
        <PronductA v-if="ticketType === 'productA'" :proveData="ticketInfo"></PronductA>
        <PronductB v-if="ticketType === 'productB'" :proveData="ticketInfo"></PronductB>
    </div>
    <div v-if="!ticketType" class="remind">
        <!-- 若是没有数据则占位 -->
        <blanket style="margin-top:100px;"></blanket>
    </div>
</div>
</template>

<script>
import AninmalA from '../components/Animal-A';
import AninmalB from '../components/Animal-B';
import PronductA from '../components/Product-A';
import PronductB from '../components/Product-B';
import axios from 'axios';
import Blanket from '../components/BlanketHolder';
export default {
    components: {
        AninmalA,
        AninmalB,
        PronductA,
        PronductB,
        Blanket
    },
  
    data() {
        return {
            ticketInfo: {},
            ticket: '',
            type: '',
            url: ''
        };
    },

    mounted() {
        let query = this.$route.query;
        if (query && query.id && query.type && query.request) {
            this.ticket = query.id;
            this.type = query.type;
            this.url = query.request;
            this.getTicketInfo(this.ticket, this.type);
        }
        // hidden tabbar
        this.hideTabbar();
    },

    beforeDestroy() {
        this.showTabbar();
    },
    computed: {
        ticketType() {
            if (this.ticketInfo && this.ticketInfo.type && this.type) {
                return this.type + this.ticketInfo.type;
            } else {
                return '';
            }
        }
    },

    methods: {
        // getTicketInfo
        getTicketInfo(ticket, type) {
            this.$toast.loading({
                duration: 0,
                message: '数据加载中...',
                forbidClick: true,
                loadingType: 'spinner'
            });
            axios.post(`${process.env.VUE_APP_TICKET_BASE_URL}/api/tickets/` + this.url, {
                ticket,
                type
            })
                .then(res => {
                    this.$toast.clear();
                    if (res.data.data && res.data.data.length != 0) {
                        let data = res.data.data;
                        data.startRegion = `${data.startProvince.name}${data.startCity.name ? data.startCity.name:''}${data.startCounty.name ? data.startCounty.name :''}${data.startTown.name? data.startTown.name: ''}${data.startPlace ? data.startPlace: ''}`;
                        data.endRegion = `${data.endProvince.name}${data.endCity.name ? data.endCity.name:''}${data.endCounty.name ? data.endCounty.name :''}${data.endTown?( data.endTown.name? data.endTown.name: '' ): ''}${data.endPlace ? data.endPlace: ''}`;
                        let ticketInfo = {};
                        ticketInfo = data;
                        if (type === 'animal') {
                            ticketInfo.ticketType = data.animal.name;
                        } else {
                            if (data.product.parent.label) {
                                ticketInfo.ticketType = data.animal.name + '/' + data.product.parent.label + '/' + data.product.label;
                            } else {
                                ticketInfo.ticketType = data.animal.name + '/' + data.product.label;
                            }
                            if (data.startCompany) {
                                ticketInfo.startRegion = data.startRegion + data.startRegion + `${data.startCompany.name? data.startCompany.name: ''}`;
                            }
                        }
                        ticketInfo.stationList = res.data.eventResults;
                        this.ticketInfo = ticketInfo;
                    } else {
                        this.$toast.fail('查无此证!');
                    }
                })
                .catch(err => {
                    this.$toast.clear();
                    window.console.log(err);
                    if (err.status !== 401) {
                        this.$toast.fail('异常, 请重试！' + err);
                    }
                });
        }
    }
};
</script>

<style lang="less" scoped>
.container {
    padding-bottom: 100px;
}

// 通过的检查站
.result-station {
    padding-bottom: 20px;
    display: flex;
    font-size: 26px;
    color: #333;
    align-items: center;

    & .dot {
        width: 16px;
        height: 16px;
        background-color: #ff791f;
        border-radius: 50%;
        margin-right: 15px;
    }

    & .station-count {
        margin: 0 20px;
        color: #000;
    }

    & .station-detail {
        color: @main-color;
    }
}

// :placeholder
.remind {
    display: flex;
    justify-content: center;
    align-items: center;
}

.remind img {
    margin-top: 150px;
    width: 350px;
}
</style>
