<template>
<div class="records">
    <van-sticky :offset-top="0">
        <div class="styicky">
            <div class="date-view">
                <van-field v-model="date" readonly is-link right-icon="calender-o"  style="text-align:left;color:#666;" label="到店日期" input-align="right" @click="showCalendar" />
            </div>
            <div class="section-title">结果</div>
        </div>
    </van-sticky>

    <!-- 日历 -->
    <van-popup v-model="show" style="width:80%;">
        <div>
            <Calendar @choseDay="clickDay" />
        </div>
    </van-popup>

    <!-- cell -->
    <div class="cell" v-for="(item,index) in recordList" :key="index" @click="selectedCell(item)">
        <img class="cell-icon" src="https://cdn.xjy0.cn/ticket/trace/record_list.png" alt="icon">
        <div class="cell-content">
            <!-- 票号 -->
             <div class="cell-first">
                <div class="cell-code">{{item.quarantineNumber}}</div>
                <!-- <div class="cell-code">652458763315</div> -->
                <div class="hang" @click.stop="hangTicket(item)">
                    扫码挂票
                </div>
            </div>
            <!--到店时间  -->
            <div class="cell-row">
                <span class="row-title">到店时间</span>
                <span class="row-content"> {{item.arriveDate}}</span>
            </div>
            <!--到店产品  -->
            <div class="cell-row">
                <span class="row-title">到店产品</span>
                <span class="row-content"> {{item.product}}</span>
            </div>
            <!--到店重量  -->
            <div class="cell-row">
                <span class="row-title">到店重量</span>
                <span class="row-content"> {{item.number}}</span>
            </div>
        </div>
    </div>

    <!-- 若是没有数据则占位 -->
    <blanket v-if="!placeBlanket" style="margin-top:50px;"></blanket>
</div>
</template>

<script>
import Blanket from '../components/BlanketHolder';
import Calendar from 'vue-calendar-component';
import {
    getDate
} from '../utils/utils';
import https from '../utils/request';
import localStorage from '../utils/storage';
export default {
    components: {
        Calendar,
        Blanket
    },

    computed: {
        placeBlanket() {
            return this.recordList.length;
        }
    },

    data() {
        return {
            date: '',
            recordList: [],
            show: false
        };
    },
    mounted() {
        // 从vuex取
        this.date = this.$store.state.recordDate;
        this.getRecordsList();
    },

    methods: {
        hangTicket(item){
            this.$router.push({
                path: '/hangTicket',
                query: {
                    ticket: item.quarantineNumber
                }
            });
        },
        showCalendar() {
            this.show = true;
        },
        clickDay(e) {
            this.show = false;
            this.date = getDate(e);
            this.getRecordsList();
            this.$store.commit('SET_DATE', this.date);
            window.console.log('选中日期：', getDate(e));
        },

        selectedCell(item) {
            console.log(item);
            let shop = {
                address: item.shop.address,
                code: item.shop.code,
                corp: item.shop.corp,
                name: item.shop.name,
                arriveDate: item.arriveDate,
                photos: item.shop.photos
            };
            this.$store.commit('SET_RECORDSHOP', shop);
            this.$router.push({
                path: '/records/newDetail',
                query: {
                    ticket: item.quarantineNumber,
                    type: 'product'
                }
            });
        },

        getRecordsList() {
            let curShop = localStorage.get('shopId');
            if (curShop !== 'null' && curShop) {
                this.$toast.loading({
                    duration: 0,
                    message: '数据加载中...',
                    forbidClick: true,
                    loadingType: 'spinner'
                });
                https.get(`/api/arrives?arriveDate=${this.date}&shopId=${curShop}`)
                    .then(res => {
                        this.$toast.clear();
                        if (res.status === 200 && res.data && res.data.data) {
                            this.recordList = res.data.data.map(item => {
                                let items = item;
                                return items;
                            });
                        } else {
                            this.recordList = [];
                        }
                    })
                    .catch(err => {
                        this.$toast.clear();
                        if (err.status !== 401) {
                            this.$toast.fail('异常, 请重试！' + err);
                        }
                    });
            } else {
                this.$toast.fail('当前暂无默认店铺！');
            }
        }

    }
};
</script>

<style lang="less" scoped>
.records {
    padding-bottom: 120px;
}

.styicky {
    background-color: #f8f8f8;
    margin: 0 10px;
    padding-top: 40px;

    & .date-view {
        margin: 0 20px;
        padding: 6px 10px;
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    }

    & .section-title {
        margin: 0 20px;
        padding: 20px 0 10px;
        color: @lightgray;
        text-align: left;
    }
}

.cell {
    display: flex;
    margin: 0 20px 30px 20px;
    padding: 25px;
    border-radius: 20px;
    background-color: #fff;
    align-items: center;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);

    & .cell-icon {
        width: 150px;
        height: 200px;
        margin-right: 20px;
        border-radius: 10px;
        object-fit: cover;
    }

    & .cell-content {
        text-align: left;
        color: @gray;
        
        & .cell-first {
            display: flex;
            align-items: center;
            & .cell-code {
                color: @mainface;
                font-size: @font-40px;
            }
        }

        & .cell-row {
            margin-top: 10px;

            .row-title {
                margin-right: 20px;
            }

            .row-content {
                color: @black;
            }
        }
    }
}

// vant css
.van-cell {
    font-size: @font-34px  !important;
    background-color: rgba(255, 255, 255, 0);
}
.van-field__label {
    width: 120px;
}

.hang {
    width: 170px;
    height: 55px;
    line-height: 55px;
    position: absolute;
    right: 20px;
    font-size: @font-32px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background-image: linear-gradient(to right, #20c854, #7edf39);
    color: #fcfcfc;
    text-align: center;
}

</style>
