<template>
  <div class="detail">
    <div class="bg-top">
      <!-- top图 -->
      <van-image
        style="margin-bottom: 10px"
        width="100vw"
        height="260"
        :src="headImage"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
    </div>
    <!-- 卡环编号 -->
    <div class="snap-view">
      <div class="snap">卡环编号:</div>
      <div class="code">{{ snapring }}</div>
    </div>
    <!-- 标签 -->
    <van-tabs
      v-model="active"
      scrollspy
      sticky
      v-if="productShow"
      color="#20c854"
      line-height="5px"
      title-active-color="#31af01"
      title-inactive-color="#333"
    >
      <van-tab title="核酸检测">
        <div class="ticket">
          <NAT v-if="ticketInfo" :result="trace" />
        </div>
      </van-tab>

      <van-tab title="屠宰检疫">
        <div class="ticket">
          <Product class="product" v-if="ticketInfo" :proveData="ticketInfo" />
        </div>
      </van-tab>
      <van-tab title="产地检疫">
        <div class="ticket">
          <Animal :proveData="aninmalTicket" />
        </div>
      </van-tab>
      <van-tab title="畜主信息">
        <div class="ticket">
          <Owner :snapring="snapring"></Owner>
        </div>
      </van-tab>
    </van-tabs>

    <div v-else class="box">
      <blanket></blanket>
      <span class="no-form">暂无追溯信息</span>
    </div>
    <div class="logo">
      <img
        class="company"
        src="//cdn.xjy0.cn/trace-mini/image/1530070637.png"
        alt="company"
      />
    </div>
  </div>
</template>

<script>
import Product from "../components/Product";
import Animal from "../components/Animal";
import Owner from "../components/GXOwner";
import Blanket from "../components/BlanketHolder";
import url from "url";
import queryString from "querystring";
import NAT from "@/components/NAT";
export default {
    components: {
        Product,
        Animal,
        Owner,
        Blanket,
        NAT,
    },
    data() {
        return {
            ticketInfo: {},
            aninmalTicket: {},
            childTicketType: "",
            snapring: "",
            regionPic: "",
            active: 0,
            trace: {
                hangTicketDate: '2020-11-09',
                barCode: '568754123563'
            }
        };
    },


    created() {},
    mounted() {
        let ring = url.parse(location.href).query;
        let query = queryString.parse(ring);
        this.snapring = query.number;
        // hidden tabbar
        this.hideTabbar();
        // 新疆
        if (this.snapring === "0093778421") {
        // 票的详情
            this.ticketInfo = {
                quarantineNumber: "6526968100",
                ticketType: "	鸡/肉产品",
                numUnit: "贰佰公斤",
                ownerName: "苏有才",
                contractor: "苏有才",
                modeOfTransport: "公路",
                licensePlateNo: "新E3E643",
                disinfectionMethod: "0.5%过氧乙酸",
                quarantinedDate: "2020-11-13 15:58:00",
                ticketerFullname: "努尔古则丽·麦图荪",
                startRegion: "疆博州博乐市青得里镇博乐市伊乐屠宰",
                daysByUpperChinese: "壹",
                deleted: false,
            };

            this.aninmalTicket = {
                quarantineNumber: "4524274998",
                ownerName: "李梦",
                ticketType: "鸡",
                numberUnit: "贰佰贰拾羽",
                contractor: "付敬忠",
                modeOfTransport: "公路",
                licensePlateNo: "桂B85849",
                disinfectionMethod: "0.5%过氧乙酸",
                brucellosisDate: "",
                startRegion: "广西壮族自治区南宁吴圩镇明阳屠宰场",
                endRegion: "新疆博州博乐市青得里镇博乐市伊乐屠宰",
                quarantinedDate: "2020-11-10 18:22:00",
                ticketerFullname: "巴合提汗·木哈买提热",
                daysByUpperChinese: "叁",
                deleted: false,
            };
        } else {
            // 广西、
            // 票的详情
            this.ticketInfo = {
                quarantineNumber: "4550035514",
                ticketType: "鸡",
                numUnit: "叁拾只",
                ownerName: "苏有才",
                contractor: "卢博",
                modeOfTransport: "公路",
                licensePlateNo: "桂B75151, 桂BC7451 ",
                disinfectionMethod: "0.5%过氧乙酸",
                quarantinedDate: "2020-10-20 15:58:00",
                ticketerFullname: "王建良",
                startRegion: "广西壮族自治区柳州肉类联合加工厂",
                daysByUpperChinese: "壹",
                deleted: false,
            };

            this.aninmalTicket = {
                quarantineNumber: "4524316620",
                ownerName: "陈鑫",
                ticketType: "鸡",
                numberUnit: "贰拾只",
                contractor: "卢博",
                modeOfTransport: "公路",
                licensePlateNo: "桂81669 ",
                disinfectionMethod: "0.5%过氧乙酸",
                brucellosisDate: "",
                startRegion: "广西壮族自治区南宁吴圩镇明阳屠宰场",
                endRegion: "广西壮族自治区柳州肉类联合加工厂",
                quarantinedDate: "2020-10-19 18:22:00",
                ticketerFullname: "王伟",
                daysByUpperChinese: "壹",
                deleted: false,
            };
        }
    },

    beforeDestroy() {
        this.showTabbar();
    },

    computed: {
        productTicketType() {
            if (this.ticketInfo && this.ticketInfo.type) {
                return "product" + this.ticketInfo.type;
            } else {
                return "";
            }
        },
        productShow() {
            let keys = Object.keys(this.ticketInfo);
            if (keys && keys.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        headImage() {
            if (this.snapring === '0093778421') {
                return '//cdn.xjy0.cn/trace/trace/xj.png';
            } else {
                return '//cdn.xjy0.cn/trace/trace/gx1.png';
            }
        }
    },
};
</script>

<style lang="less" scoped>
.detail {
  padding-bottom: 20px;
  height: 100%;

  /deep/ .van-tab {
    font-size: @font-34px;
  }
  /deep/ .van-tabs--line .van-tabs__wrap {
    height: 110px;
  }
}

.logo {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}

.top-img {
  margin-bottom: 20px;
}

.company {
  width: 50%;
  height: 100px;
  object-fit: contain;
}

.product {
  margin-bottom: 20px;
}

.ticket {
  margin: 25px;
  overflow-x: hidden;
  overflow-y: hidden;

  & .section {
    text-align: left;
    padding: 10px;
    border-left: 8px solid @mainface;
  }
}

.snap-view {
  background-color: #fff;
  margin: 0 25px 20px;
  padding: 20px 25px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  text-align: left;

  .snap {
    position: relative;
    padding-left: 15px;
    color: #31af01;
  }

  .code {
    margin-left: 20px;
    font-size: @font-36px;
    color: #666;
  }
}

.snap::before {
  display: block;
  content: "";
  width: 10px;
  height: 32px;
  background: linear-gradient(0, #20c854, #7cdf3a);
  position: absolute;
  top: 7px;
  left: 0;
  border-radius: 3px;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
}

.no-form {
  font-size: @font-28px;
  color: #666;
}
</style>
<style lang="less">
