<template>
<div class="jianyi">
    <div class="jianyi">
        <div class="jianyi-box">
            <h4>动物检疫合格证明<span>(产品B)</span></h4>
            <div class="table-top">
                <!-- <div class="table-top-left">二维码号: 300931 0636197506236</div> -->
                <div class="table-top-right">No.{{proveData.quarantineNumber}}</div>
            </div>
            <table class="jianyi-table" cellspacing="0">
                <tr>
                    <td class="red">货主</td>
                    <td colspan="3">{{proveData.ownerName}}</td>
                    <td class="red">产品名称</td>
                    <td colspan="3">{{proveData.ticketType}}</td>
                <tr>
                    <td class="red">数量及单位</td>
                    <td colspan="3">{{proveData.numberByUpperChinese + proveData.unit}}</td>
                    <td class="red">产地</td>
                    <td colspan="3">{{proveData.productarea}}</td>
                </tr>

                <tr>
                    <td class="red">生产单位名称地址</td>
                    <td colspan="6">{{proveData.startRegion}}</td>
                </tr>
                <tr>
                    <td class="red">目的地</td>
                    <td colspan="6">{{proveData.endRegion}}</td>
                </tr>

                <tr>
                    <td class="red">承运人</td>
                    <td colspan="3">{{proveData.contractor}}</td>
                    <td class="red">联系电话</td>
                    <td colspan="3">{{proveData.contractorMobile}}</td>
                <tr>

                <tr>
                    <td class="red">运载方式</td>
                    <td colspan="3">{{proveData.modeOfTransport}}</td>
                    <td class="red">运载工具牌号</td>
                    <td colspan="3">{{proveData.licensePlateNo}}</td>
                <tr>

                <tr>
                    <td class="red">运载工具消毒情况</td>
                    <td colspan="6">{{proveData.disinfectionMethod}}</td>
                </tr>

                <tr>
                    <td class="red">检疫标志号</td>
                    <td colspan="6">{{proveData.quarantineFlag}}</td>
                </tr>

                <tr>
                    <td class="red">{{proveData.quarantineType ? proveData.quarantineType +'证': ''}}检疫证号</td>
                    <td colspan="6">
                        <div class="unionclass">
                            <span style="margin-right:15px; " v-for="(code, index) in unionList" :key="index" @click="toRelateTicket(code)" class="link-style">{{code? code + ',' : ''}}</span>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td class="red">备注</td>
                    <td colspan="6">{{proveData.remark}}</td>
                </tr>

                <tr>
                    <td colspan="6">
                        <div class="qianzi">
                            <div class="red qianzi-left">本批产品经检疫合格,应于<span>{{proveData.daysByUpperChinese}}</span>日内到达有效。</div>
                            <div class="qianzi-right">
                                <p><span class="red">官方兽医签字:</span>{{proveData.ticketerFullname}}</p>
                                <p><span class="red">签发日期:</span> {{proveData.quarantinedDate}}</p>
                            </div>
                        </div>
                    </td>
                </tr>

            </table>
            <div class="yejiao">动物卫生监督所联系电话: {{proveData.animalHealthMobile}}</div>
            <!-- 作废 -->
            <div class="zuofei" v-if="proveData.deleted"><img src="../assets/image/zuofei.png" alt="zuofei"></div>
        </div>
    </div>

</div>
</template>

<script>
import {
    REQUEST_TYPE
} from '../assets/constant/index';
export default {
    props: {
        // 证的数据
        proveData: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            unionList: []
        };
    },

    watch: {
        proveData: {
            handler(ticket) {
                if (Object.keys(ticket)) {
                    let union = ticket.animalTicket;
                    if (union !== null && union) {
                        /* eslint-disable */
                        this.unionList = union.split(/[, ；，; 、.\/]/);
                    }
                }
            },
            immediate: true
        },
    },

    mounted() {
        // window.console.log('产品----', this.proveData);
    },

    methods: {
        toRelateTicket(code) {
            if (this.proveData.quarantineType && code) {
                let type;
                let request = REQUEST_TYPE[this.proveData.quarantineType];
                if (this.proveData.quarantineType.includes('动物')) {
                    type = 'animal';
                } else if (this.proveData.quarantineType.includes('产品')) {
                    type = 'product';
                }
                this.$router.push({
                    name: 'relate',
                    query: {
                        type,
                        id: code,
                        request
                    }
                })
            }
        }
    }
};
</script>

<style lang="less" scoped>

.link-style {
    color: @main-color;
}
.unionclass {
    height: 100px;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    
}
</style>
<style scoped="scoped" src="../assets/css/style.css"/>
