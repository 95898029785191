<template>
<div class="container">
    <iframe id="mapPage" width="100%" height="100%" allow="geolocation" frameborder=0 src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=G2SBZ-VNAL3-EUB3Q-3JBJB-RUIWK-PRFIJ&referer=trace-mobile">
    </iframe>
</div>
</template>

<script>
export default {
    mounted() {
        this.hideTabbar();
        window.addEventListener('message', this.getLocation);
    },
    methods: {
        getLocation(event) {
            // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
            var loc = event.data;
            if (loc && loc.module == 'locationPicker') {//防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
                window.console.log('location', loc);
                this.$emit('location', loc);
            }
        }
    }
};
</script>

<style lang="less" scoped>
    .container {
        width: 100vw;
        height: 100vh;
    }
</style>