<template>
<div class="check">
    <div class=" bg-top"></div>
    <div class="content">
      <!-- <div class="color-view"></div> -->
        <!-- 票 -->
        <div class="input-view">
            <div class="input">
                <van-field v-model="code" type="number" placeholder="请输入产品检疫证号" @focus="focusHideTab" @blur="blurShowTab">
                    <van-icon slot="right-icon" :name="scanIcon" @click="scanQR" />
                </van-field>
            </div>
        </div>

        <!-- 查验按钮 -->
        <div class="button-view">
            <van-button @click="checkedTickets" style="width:85%;" round color="linear-gradient(to right, #20c854, #7edf39)">查询</van-button>
        </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src
import wx from 'weixin-js-sdk';
import url from 'url';
import queryString from 'querystring';
import axios from 'axios';
export default {
    data() {
        return {
            type: 'product',
            code: '',
            scanIcon: require('../assets/image/scan.png')
        };
    },

    created() {
        this.setWxConfig();
    },

    methods: {
        scanQR() {
            wx.scanQRCode({
                needResult: 1,
                scanType: ['qrCode'],
                success: (res) => {
                    let result = res.resultStr;
                    let ticket = url.parse(result).query;
                    let query = queryString.parse(ticket);
                    if (query && query.id && query.type && (result.includes('//ticket-mobile.xjy0.cn'))) {
                        this.code = query.id;
                        this.type = query.type;
                        this.checkedTickets();
                    } else if (query && query.i && query.t && (result.includes('//t.xjy0.cn'))) {
                        const ticketType = {
                            a: 'animal',
                            p: 'product'
                        };
                        this.code = query.i;
                        this.type = ticketType[query.t];
                        this.checkedTickets();
                    } else if(result.includes('//xjy0.cn')) {
                        let otherQr = result.match(/[0-9]{9,10}/);
                        this.code = otherQr[0];
                        // 通过耳标号查询产品票
                        this.getProductTicketInfo();
                    } else {
                        let qr = result.match(/['DA'|'CA'|'DB'|'CB']{2}[0-9]{9,10}/);
                        if (qr && qr.length > 0) {
                            let btTicket = qr[0];
                            const btType = {
                                D: 'animal',
                                C: 'product'
                            };
                            this.typ = btType[btTicket.slice(0, 1)];
                            this.code = btTicket.match(/[0-9]{9,10}/)[0];
                            this.checkedTickets();
                        } else {
                            // let otherQr = result.match(/[0-9]{9,10}/);
                            // this.code = otherQr[0];
                            this.code = result;
                        }
                    }
                },
                fail: (err) => {
                    window.console.log(err);
                    this.$toast.fail( '请重试点击扫码！！');
                    this.setWxConfig();
                }
            });
        },

        // tabbar 的处理
        focusHideTab() {
            // hidden tabbar
            this.hideTabbar();
        },

        // 失去焦点
        blurShowTab() {
            this.showTabbar();
        },
        getProductTicketInfo() {
            if (!this.code) return;
            this.$toast.loading({
                duration: 0,
                message: "数据加载中...",
                forbidClick: true,
                loadingType: "spinner",
            });
            console.log("开票地址：" + process.env.VUE_APP_TICKET_BASE_URL);
            axios
                .post(
                    `${process.env.VUE_APP_TICKET_BASE_URL}/api/tickets/getProductTicket`,
                    {
                        kahuan: this.code,
                    }
                )
                .then((res) => {
                    this.$toast.clear();
                    if (res.data.data && res.data.data.length != 0) {
                        let data = res.data.data;
                        data.startRegion = `${data.startProvince.name}${
                            data.startCity.name ? data.startCity.name : ""
                        }${data.startCounty.name ? data.startCounty.name : ""}${
                            data.startTown.name ? data.startTown.name : ""
                        }${data.startPlace ? data.startPlace : ""}`;
                        data.endRegion = `${data.endProvince.name}${
                            data.endCity.name ? data.endCity.name : ""
                        }${data.endCounty.name ? data.endCounty.name : ""}${
                            data.endTown ? (data.endTown.name ? data.endTown.name : "") : ""
                        }${data.endPlace ? data.endPlace : ""}`;
                        let ticketInfo = {};
                        ticketInfo = data;
                        ticketInfo.ticketType = data.animal.name;
                        if (data.product.parent.label) {
                            ticketInfo.ticketType =
                                  data.animal.name +
                                  "/" +
                                  data.product.parent.label +
                                  "/" +
                                  data.product.label;
                        } else {
                            ticketInfo.ticketType =
                                  data.animal.name + "/" + data.product.label;
                        }
                        if (data.startCompany) {
                            ticketInfo.startRegion =
                                  data.startRegion +
                                  `${data.startCompany.name ? data.startCompany.name : ""}`;
                        }
                        ticketInfo.numUnit = data.number + data.unit;
                        this.ticketInfo = ticketInfo;

                        
                        this.$store.commit('SET_CHECKEDRESULT', ticketInfo);
                        this.$store.commit('SET_TYPE', 'product');
                        this.$router.push({
                            path: '/arrive'
                        });
                    } 
                })
                .catch((err) => {
                    this.$toast.clear();
                    if (err.status !== 401) {
                        this.$toast.fail("查询异常, 请重试！" + err);
                    }
                });
        },
        // 查验
        checkedTickets() {
            if (this.type === 'animal') {
                this.$toast({
                    type: 'fail',
                    message: '请填写产品检疫证号！！'
                });
                return;
            }
            if (this.code) {
                this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: '数据加载中……'
                });
                axios.post(`${process.env.VUE_APP_TICKET_BASE_URL}/api/tickets/getProductTicket`, {
                    ticket: this.code,
                    type: 'product'
                })
                    .then(res => {
                        this.$toast.clear();
                        if (res.data.data && res.data.data.length != 0) {
                            let data = res.data.data;
                            data.startRegion = `${data.startProvince.name}${data.startCity.name ? data.startCity.name:''}${data.startCounty.name ? data.startCounty.name :''}${data.startTown.name? data.startTown.name: ''}${data.startPlace ? data.startPlace: ''}`;
                            data.endRegion = `${data.endProvince.name}${data.endCity.name ? data.endCity.name:''}${data.endCounty.name ? data.endCounty.name :''}${data.endTown?( data.endTown.name? data.endTown.name: '') : ''}${data.endPlace ? data.endPlace: ''}`;
                            let ticketInfo = {};
                            ticketInfo = data;
                            ticketInfo.ticketType = data.animal.name;
                            if (data.product.parent.label) {
                                ticketInfo.ticketType = data.animal.name + '/' + data.product.parent.label + '/' + data.product.label;
                            } else {
                                ticketInfo.ticketType = data.animal.name + '/' + data.product.label;
                            }
                            if (data.startCompany) {
                                ticketInfo.startRegion = data.startRegion + `${data.startCompany.name? data.startCompany.name: ''}`;
                            }
                            // window.console.log('处理的票-----', res, ticketInfo);

                            this.$store.commit('SET_CHECKEDRESULT', ticketInfo);
                            this.$store.commit('SET_TYPE', 'product');
                            this.$router.push({
                                path: '/arrive'
                            });
                        } else {
                            this.$toast.fail('查无此票!');
                        }
                    })
                    .catch(err => {
                        this.$toast.clear();
                        if (err.status !== 401) {
                            this.$toast.fail('查询异常, 请重试！' + err);
                        }
                    });
            } else {
                this.$toast('请填写产品检疫证号！！！');
            }
        },

    }
};
</script>

<style lang="less" scoped>
.bg-top {
    height: 400px;
    background: url('https://cdn.xjy0.cn/ticket/trace/check_topbg.png') no-repeat top;
    background-origin: content-box;
    background-clip: content-box;
    background-size: 100vw 400px;
}

.content {
    position: relative;
    top: -100px;
    .color-view {
      width: 100px;
      height: 100px;
      background-color: red;
    }
}

.input-view {
    // position: fixed;
    padding: 0 20px;

    & .input {
        padding: 5px;
        margin-top: 50px;
        border-radius: 80px;
        background-color: #fff;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    }
}

.button-view {
    text-align: center;
    margin-top: 100px;
}

// vant css
.van-cell {
    font-size: @font-36px  !important;
    background-color: rgba(255, 255, 255, 0);
}
.van-icon {
    font-size: 50px !important;
}
// .van-field {
//     height: 100px;
// }
</style>
