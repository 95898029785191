// vue 的全局函数定义
import wx from 'weixin-js-sdk';
export default function (Vue) { //全部改为同步，保证操作时，wx.config能够成功执行。
    //全部改为同步，保证操作时，wx.config能够成功执行。
    Vue.prototype.setWxConfig = async function () {
        console.debug('进入setWxConfig()...');

        let request = location.href.split("#");

        //举例：http://m.doctor.xjy0.cn/
        //举例：http://m.doctor.xjy0.cn/?code=0011SSSx1uLfDb0mrlVx1e5RSx11SSSu&state=/home
        let url = request[0];

        // url编码，否则http://doctor.xjy0.cn/api/wechat/getSign?url=http://m.doctor.xjy0.cn/?code=0011SSSx1uLfDb0mrlVx1e5RSx11SSSu&state=/home
        // 服务端会解析到两个参数url=http://m.doctor.xjy0.cn/?code=0011SSSx1uLfDb0mrlVx1e5RSx11SSSu,state=/home
        // 应该只有一个参数 url=http://m.doctor.xjy0.cn/?code=0011SSSx1uLfDb0mrlVx1e5RSx11SSSu&state=/home
        // 转换前： http://m.doctor.xjy0.cn/?code=0011SSSx1uLfDb0mrlVx1e5RSx11SSSu&state=/home
        // 转换后： http%3a%2f%2fm.doctor.xjy0.cn%2f%3fcode%3d0011SSSx1uLfDb0mrlVx1e5RSx11SSSu%26state%3d%2fhome
        url = encodeURIComponent(url);

        try {
            //1.同步获取页面url地址签名
            let res = await this.$https.get('/api/wechat/getSign?url=' + url);
            console.debug(res);
            if (res.status == 200 && res.data) {
                let result = res.data;
                console.debug(result);

                if (this.$store.state.debugging) {
                    // alert(JSON.stringify(result));
                    console.info(JSON.stringify(result));
                }

                try {
                    //2.同步通过config接口注入权限验证配置
                    await syncWxConfig(result.appId, result.timestamp, result.nonceStr, result.signature);
                } catch (err) {
                    this.$toast(JSON.stringify(err));
                }
            } else {
                console.error(JSON.stringify(res));
                this.$toast(JSON.stringify(res));
            }
        } catch (err) {
            this.$toast(JSON.stringify(err));
        }
    };

    // 同步通过config接口注入权限验证配置
    function syncWxConfig(appId, timestamp, nonceStr, signature) {
        return new Promise(function (resolve, reject) {
            console.debug('开始配置 wx.config()');
            wx.config({
                debug: false,
                appId: appId,
                timestamp: timestamp,
                nonceStr: nonceStr,
                signature: signature,
                jsApiList: ['scanQRCode', 'chooseImage', 'getLocalImgData', 'previewImage', 'getLocation','openLocation']
            });

            wx.ready(function () {
                console.debug('开始调用 wx.ready()');
                resolve();
            });

            wx.error(function (res) {
                alert(JSON.stringify(res));
                reject(res);
            });
        });
    }
    
    // 隐藏tabbar
    Vue.prototype.hideTabbar = function () {
        let tabbar = document.querySelector('#tabbar');
        tabbar.style.display = 'none';
    };
    // 显示tabbar
    Vue.prototype.showTabbar = function () {
        let tabbar = document.querySelector('#tabbar');
        tabbar.style.display = 'flex';
    };
}
