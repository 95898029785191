import * as types from './types';
// import localStorage from '../utils/storage';
const mutations = {
    [types.SET_CHECKEDRESULT](state, result) {
        state.checkedData = result;
    },
    [types.SET_TYPE] (state, type) {
        state.ticketType = type;
    },
    [types.SET_DATE] (state, date) {
        state.recordDate = date;
    },
    [types.SET_INFO] (state, info) {
        state.info = info;
    },
    [types.SET_RECORDSHOP](state, shop) {
        state.recordShop = shop;
    }
};
export default mutations;