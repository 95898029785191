<template>
    <div class="ticket-view">
        <!-- 票的类型 -->
        <div class="ticket-title">
            <span>核酸检测</span>
        </div>
        <!-- 票的信息 -->
        <div class="ticket-info">
          <van-field :value="result.barCode" readonly label="核酸检测条形码" label-width="140px" input-align="right"  style="text-align: left;color: #333" border/>
          <div class="nat">
            <div class="result">核酸检测结果</div>
            <img  src="//cdn.xjy0.cn/trace/trace/check-trace.png">
            <div class="time">采样时间 {{result.hangTicketDate}}</div>
            <div class="good">检测结果：阴性</div>
          </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    props: {
        result: {
            type: Object
        }
    },

    data() {
        return {
        };
    },

    mounted() {
        // this.getData();
    },

    methods: {
        getData() {
            const n = this.number ;
            axios.get(`http://trace-test.xjy0.cn/api/ncovHangTicket/getNcov?number=${n}`)
                .then(res => {
                    console.log(res.data);
                    if(res.data && res.data.success) {
                        this.result = res.data.data;
                    }
                })
                .catch(err => {
                    if (err.status !== 401) {
                        this.$toast.fail('核酸检测数据获取失败' + err);
                    }
                });
        }
    }
};
</script>

<style lang="less" scoped>
.ticket-view {
    // background: url(../assets/image/shuiyin.png) no-repeat center;
    // background-size: 350px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px 30px 20px;
    text-align: left;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    position: relative;
    .ticket-title {
        position: relative;
        padding-bottom:  15px;
        color: #31af01;
    }
    .ticket-title::before {
        display: block;
        content: "";
        width: 9px;
        height: 30px;
        background: linear-gradient(0, #20c854, #7cdf3a);
        position: absolute;
        top: 7px;
        left: 0;
        border-radius: 3px;
    }
    .ticket-title::after {
        content: " ";
        position: absolute;
        pointer-events: none;
        box-sizing: border-box;
        -webkit-transform-origin: center;
        transform-origin: center;
        top: auto;
        left: 15px;
        right: 0;
        bottom: 0;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
        border-bottom: 2px dashed #ddd;
    }
    .ticket-title span {
        margin-left: 20px;
    }

    .nat {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      & img {
        width: 40%;
      }
      .result {
        color: rgb(41, 181, 228);
      }

      .time {
        background-color: #f2f2f2;
        padding: 10px 30px;
        border-radius: 40px;
        font-size: 24px;
        margin-bottom: 20px;
      }

      .good {
        color: #20c854;
        font-size: 28px;
      }
    }
}

// vant css
.van-cell {
    font-size: @font-32px  !important;
    background-color: rgba(0,0,0,0);
}
</style>
