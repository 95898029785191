<template>
<div class="jianyi">
    <div class="jianyi-box">
        <h4>动物检疫合格证明<span>(动物A)</span></h4>
        <div class="table-top">
            <!-- <div class="table-top-left">二维码号: 300931 0636197506236</div> -->
            <div class="table-top-right">No.{{proveData.quarantineNumber}}</div>
        </div>
        <table class="jianyi-table" cellspacing="0">
            <tr>
                <td class="red">货主</td>
                <td colspan="3">{{proveData.ownerName}}</td>
                <td class="red">联系电话</td>
                <td colspan="4">{{proveData.ownerMobile}}</td>
            <tr>
                <td class="red">动物种类</td>
                <td colspan="3">{{proveData.ticketType}}</td>
                <td class="red">数量及单位</td>
                <td colspan="4">{{proveData.numberByUpperChinese + proveData.unit}}</td>
            </tr>

            <tr>
                <td class="red">启运地点</td>
                <td colspan="7">{{proveData.startRegion}}</td>
            </tr>
            <tr>
                <td class="red">到达地点</td>
                <td colspan="7">{{proveData.endRegion}}</td>
            <tr>

            <tr>
                <td class="red">用途</td>
                <td colspan="7">{{proveData.usage}}</td>
            </tr>

            <tr>
                <td class="red">承运人</td>
                <td colspan="3">{{proveData.contractor}}</td>
                <td class="red">联系电话</td>
                <td colspan="4">{{proveData.contractorMobile}}</td>
            <tr>

            <tr>
                <td class="red">运载方式</td>
                <td colspan="3">{{proveData.modeOfTransport}}</td>
                <td class="red">运载工具牌号</td>
                <td colspan="4">{{proveData.licensePlateNo}}</td>
            <tr>

            <tr>
                <td class="red">运载工具消毒情况</td>
                <td colspan="7">{{proveData.disinfectionMethod}}</td>
            </tr>

            <tr>
                <td class="red">牲畜耳标号</td>
                <td colspan="7">
                    <div class="earclass">
                        <span v-for="(code, index) in proveData.earNumber" :key="index">{{code? code + ',' : ''}}</span>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="red">{{proveData.quarantineType ? proveData.quarantineType +'证': ''}}检疫证号</td>
                <td colspan="7">
                    <div class="unionclass">
                        <span style="margin-right:15px; " v-for="(code, index) in unionList" :key="index" @click="toRelateTicket(code)" class="link-style">{{code? code + ',' : ''}}</span>
                    </div>
                </td>
            </tr>

            <tr>
                <td class="red">备注</td>
                <td colspan="5">{{proveData.remark}}</td>
            </tr>

            <tr>
                <td colspan="6">
                    <div class="qianzi">
                        <div class="red qianzi-left">本批产品经检疫合格,应于<span>{{proveData.daysByUpperChinese}}</span>日内到达有效。</div>
                        <div class="qianzi-right">
                            <p><span class="red">官方兽医签字:</span>{{proveData.ticketerFullname}}</p>
                            <p><span class="red">签发日期:</span> {{proveData.quarantinedDate}}</p>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <div class="yejiao">注:动物卫生监督所联系电话:{{proveData.animalHealthMobile}}
        </div>
        <!-- 作废 -->
        <div class="zuofei" v-if="proveData.deleted"><img src="../assets/image/zuofei.png" alt="zuofei"></div>
    </div>
</div>
</template>

<script>
import {
    REQUEST_TYPE
} from '../assets/constant/index';
export default {
    props: {
        // 证的数据
        proveData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            unionList: []
        };
    },

    watch: {
        proveData: {
            handler(ticket) {
                if (Object.keys(ticket)) {
                    let union = ticket.animalTicket;
                    if (union !== null && union) {
                        /* eslint-disable */
                        this.unionList = union.split(/[, ；，; 、.\/]/);
                    }
                }
            },
            immediate: true
        },
    },

    mounted() {
        // window.console.log('产品----', this.proveData);
    },

    methods: {
        toRelateTicket(code) {
            if (this.proveData.quarantineType && code) {
                let type;
                let request = REQUEST_TYPE[this.proveData.quarantineType];
                if (this.proveData.quarantineType.includes('动物')) {
                    type = 'animal';
                } else if (this.proveData.quarantineType.includes('产品')) {
                    type = 'product';
                }
                this.$router.push({
                    name: 'relate',
                    query: {
                        type,
                        id: code,
                        request
                    }
                })
            }
        }
    }
};
</script>

<style lang="less" scoped>

.earclass {
    height: 200px;
    overflow-y: scroll;
    display: flex;
    // align-items: center;
    flex-wrap: wrap;
}

.earclass>span {
    font-size: 28px;
}

.link-style {
    color: @main-color;
}

.unionclass {
    height: 100px;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

}
</style>
<style scoped="scoped" src="../assets/css/style.css"/>
