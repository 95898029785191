<template>
  <div class="ticket-view">
    <!-- 票的类型 -->
    <div class="ticket-title">
      <span>畜主信息</span>
    </div>
    <!-- 户主的信息 -->
    <div class="owner-info" v-for="(item, index) in ownerList" :key="index">
      <!-- 区别大型养殖户和普通户主 -->
      <!-- //  type = 1 普通养殖户  2 企业 3 合作社 -->
      <section v-if="item.type === 1">
        <div class="flag">
          <div class="index">{{index + 1}}</div>
          <div class="name">普通畜主</div>
        </div>
        <div class="owner-type">
          <van-field
            v-model="item.name"
            readonly
            label="畜主姓名"
            style="text-align: left;color: #666"
            :border="false"
          />
          <van-field
            v-model="item.mobile"
            readonly
            label="手机号"
            style="text-align: left;color: #666"
            :border="false"
          />
          <van-field
            v-model="item.code"
            readonly
            label="身份证号"
            style="text-align: left;color: #666"
            :border="false"
          />
          <van-field
            v-model="item.address"
            readonly
            label="畜主地址"
            style="text-align: left;color: #666"
            :border="false"
          />
        </div>
      </section>
      <section v-else>
        <div class="flag">
          <div class="index">{{index + 1}}</div>
          <div class="name">{{item.typeTitle}}</div>
        </div>
        <div class="owner-type">
          <van-field
            v-model="item.name"
            readonly
            :label="item.typeTitle"
            label-width="90px"
            style="text-align: left;color: #666"
            type="textarea"
            rows="1"
            autosize
            maxlength="50"
            :border="false"
          />
          <van-field
            v-model="item.name"
            readonly
            label="负责人姓名"
            style="text-align: left;color: #666"
            :border="false"
          />
          <van-field
            v-model="item.mobile"
            readonly
            label="手机号"
            style="text-align: left;color: #666"
            :border="false"
          />
          <van-field
            v-model="item.code"
            readonly
            label="统一社会信用代码"
            style="text-align: left;color: #666"
            :border="false"
          />
          <van-field
            v-model="item.address"
            readonly
            label="养殖场地址"
            style="text-align: left;color: #666"
            :border="false"
          />
        </div>
      </section>

      <div class="owner-info media-info" v-if="item.photos">
        <div class="part-3">
          <div class="part-title">养殖场照片</div>
          <div class="photo" v-for="img in item.photos" :key="img.path">
            <van-image width="100%" height="100%" fit="cover" radius="5px" :src="img.path" @click="previewImg(index,item.photos)"/>
            <div class="introduce" v-if="img.description">{{img.description}}</div>
          </div>
          <!-- <van-empty
            v-if="!item.photos || !item.photos.length"
            image="//cdn.xjy0.cn/trace-mini/image/upload_btn_pic.png"
            description="暂无图片"
          /> -->

          <div class="part-3" v-if="item.videos">
            <div class="part-title">养殖场视频</div>
            <!--视频标签-->
            <video
              :src="item.videos"
              object-fit="fill"
              enable-play-gesture
              show-casting-button
              show-screen-lock-button
              auto-pause-if-navigate
              initial-time="0"
              :autoplay="false"
              :loop="false"
              controls
            ></video>
            <!-- <van-empty
              v-else
              image="//cdn.xjy0.cn/trace-mini/image/upload_btn_vedio.png"
              description="暂无视频"
            /> -->
          </div>

          <div class="part-4" v-if="item.introduction">
            <div class="part-title">{{item.typeTitle}}介绍</div>
            <div
              class="introduce-book"
            >{{item.introduction}}</div>
          </div>
        </div>
      </div>
      <!-- 查看防疫记录 -->
      <!-- <div class="view-record" @click="goPaaeRecords(item.id)">查看防疫记录>>></div> -->
      <div class="record-title">防疫记录</div>
      <div class="record-class">
        <van-steps direction="vertical" :active="item.recordList.length">
          <van-step v-for="record in item.recordList" :key="record.id">
            <div class="date">{{record.dateOperated}}</div>
            <div class="vaccine">{{record.vaccine}}</div>
            <div class="category">
              <van-field
                v-model="record.category"
                readonly
                label="畜别:"
                label-width="60px"
                style="text-align: left;color: #666"
                :border="false"
              />
              <van-field
                v-model="record.finals"
                readonly
                label="数量:"
                label-width="60px"
                style="text-align: left;color: #666"
                :border="false"
              />
            </div>
          </van-step>
        </van-steps>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import wx from 'weixin-js-sdk';
export default {
    data() {
        return {
            active: 0,
            ownerList: [],
            pohotoList: [
                "//cdn.xjy0.cn/paae-mini/image-202085/e6c12082-7051-4be5-a878-f168e71a8e1e.jpg",
                "//cdn.xjy0.cn/paae-mini/image11/2b69891e-87fd-41dc-85bf-e8a144a11d4b.jpg",
                "//cdn.xjy0.cn/paae-mini/image-202092/ba90a6de-820b-45b0-b419-76d7d7f147ed.jpg",
            ],
        };
    },
    props: {
    // 证的数据
        ownerId: {
            type: Array,
            default: null,
        },
        category: {
            type: String,
            default: null,
        },
    },
    watch: {
        ownerId: {
            handler(ids, o) {
                if (ids && ids.length > 0 &&!o) {
                    this.getOwners();
                }
            },
            immediate: true,
        },
    },
    mounted() {},
    methods: {
        // 预览
        previewImg(path, list) {
            // window.console.log('previewImg','文件--->', this.fileList, 'url->', this.fileUrl);
            const photos = list.map(item => {
                return item.path;
            });
            wx.previewImage({
                current: path,
                urls: photos, // 需要预览的图片http链接列表
                fail: (err) => {
                    window.console.log(err);
                    this.$toast({
                        type: 'fail',
                        message: '请重试……'
                    });
                    this.setWxConfig();
                }
            });
        },

        // 查询户主
        async getOwners() {
            for (let i = 0; i < this.ownerId.length; i++) {
                const ownerId = this.ownerId[i];
                const owner = await this.getOwner(ownerId);
                if (owner && owner != -1) {
                    this.ownerList.push(owner);
                    console.log("getOwners==>", this.ownerList);
                }
            }
        },

        getOwner(id) {
            console.log("getOwner==>", id);
            if (!id) return;
            return new Promise((resolve) => {
                this.$https
                    .get(`/api/farmers/getAllFarmersInfo?ownerId=${id}`)
                    .then(async (res) => {
                        this.$toast.clear();
                        if (res.data.success === true) {
                            let result = res.data.data;
                            const owner = result;
                            const recordList = await this.getRecords(id, this.category);
                            console.log("1111111", recordList, owner);
                            owner.recordList = recordList;
                            let title = "畜主";
                            switch (owner.type) {
                            case 1:
                                title = "畜主";
                                break;
                            case 2:
                                title = "企业";
                                break;
                            case 3:
                                title = "合作社";
                                break;
                            }
                            owner.typeTitle = title;
                            if (owner.photos) {
                                const list = JSON.parse(owner.photos);
                                owner.photos = list;
                            }
                            resolve(owner);
                        } else {
                            resolve(-1);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        if (err.status !== 401) {
                            this.$toast.fail("畜主接口异常, 请重试！" + err);
                        }
                    });
            });
        },

        getRecords(id, category) {
            return new Promise((resolve) => {
                axios
                    .post(
                        `${process.env.VUE_APP_PAAE_BASE_URL}/openapi/record/getRecords`,
                        {
                            ownerId: id,
                            start: 0,
                            length: 50,
                            uuid: "a6de36a2-fe94-487a-804c-286f9df35896",
                            isSecret: "true",
                            category: category,
                        }
                    )
                    .then((res) => {
                        this.$toast.clear();
                        console.log(333333333, res);
                        if (res.data && res.data.success === true && res.data.data) {
                            let result = res.data.data;
                            const list = result.map((item) => {
                                let date = item.dateOperated.slice(0, 10);
                                item.dateOperated = date.replace(/-/g, "/");
                                return item;
                            });
                            resolve(list);
                        } else {
                            resolve([]);
                        }
                    })
                    .catch((err) => {
                        this.$toast.clear();
                        if (err.status !== 401) {
                            this.$toast.fail("防疫记录接口异常, 请重试！" + err);
                        }
                    });
            });
        },
        goPaaeRecords(id) {
            this.$router.push({
                path: "/paaeRecords",
                query: {
                    ownerId: id,
                    category: this.category,
                },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.ticket-view {
  background: url(../assets/image/shuiyin.png) no-repeat center;
  background-size: 350px;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 30px 20px;
  text-align: left;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  // margin-bottom: 50px;
  .ticket-title {
    position: relative;
    padding-bottom: 15px;
    color: #31af01;
  }
  .ticket-title::before {
    display: block;
    content: "";
    width: 9px;
    height: 30px;
    background: linear-gradient(0, #20c854, #7cdf3a);
    position: absolute;
    top: 7px;
    left: 0;
    border-radius: 3px;
  }
  .ticket-title::after {
    content: " ";
    position: absolute;
    pointer-events: none;
    box-sizing: border-box;
    -webkit-transform-origin: center;
    transform-origin: center;
    top: auto;
    left: 15px;
    right: 0;
    bottom: 0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    border-bottom: 2px dashed #ddd;
  }
  .ticket-title span {
    margin-left: 20px;
  }
  .owner-info {
    .view-record {
      color: @mainface;
      height: 70px;
      position: relative;
      padding-left: 20px;
    }
    & section {
      .flag {
        padding: 20px 10px 20px 0;
        display: flex;
        align-items: center;
        font-size: 36px;
        color: #ff801a;
        .index {
          width: 44px;
          height: 44px;
          background-color: rgba(255, 128, 26, 0.2);
          text-align: center;
          border-radius: 10px;
        }

        .name {
          margin-left: 20px;
          font-weight: bold;
        }
      }
    }

    .owner-type {
      position: relative;
      font-size: @font-32px;
    }
    .owner-info {
      font-size: @font-32px;
      .media-info {
        padding-top: 20px;
      }

      .part-title {
        padding-left: 20px;
        color: #666;
        margin-top: 20px;
      }

      .part-3 {
        // margin-bottom: 50px;
        background-color: #fff;
        video {
          height: 350px;
          width: 100%;
          margin-top: 30px;
          border-radius: 10px;
        }
        .photo {
          position: relative;
          height: 350px;
          margin-top: 30px;

          .introduce {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.4);
            height: 80px;
            line-height: 80px;
            padding: 0 20px;
          }
        }
      }

      .part-4 {
        .introduce-book {
          margin: 30px 0;
          min-height: 40px;
        }
      }
    }
  }
  .owner-type::after {
    display: block;
    content: "";
    width: 15px;
    height: 15px;
    background: #ff801a;
    position: absolute;
    top: 20px;
    left: 0;
    border-radius: 50%;
  }
  .view-record::after {
    content: " ";
    position: absolute;
    pointer-events: none;
    // box-sizing: border-box;
    -webkit-transform-origin: center;
    transform-origin: center;
    top: auto;
    left: 15px;
    right: 0;
    bottom: 0;
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    border-bottom: 2px solid #ddd;
  }
}
.category {
  display: flex;
  .van-cell {
    padding-left: 0 !important;
    font-size: 28px !important;
  }
}
.date {
  font-size: @font-30px;
  color: #ff801a;
}
.vaccine {
  color: #666;
  font-family: "微软雅黑";
  text-align: left;
  font-size: @font-28px;
  font-weight: 600;
}
.record-title {
  padding: 20px 0 0 20px;
  font-size: @font-32px;
}
.record-class {
  padding-top: 20px;
  /deep/ .van-steps {
    background-color: #f6f6f6;
  }
}
// vant css
.van-cell {
  font-size: @font-32px !important;
  background-color: rgba(0, 0, 0, 0);
  padding: 5px 20px !important;
}
</style>