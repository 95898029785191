<template>
<div class="me">
    <div class="bg-top">
        <i class="avatar">
            <img class="me-avatar" alt="avatar" :src="meAvatar"></i>
        <div class="me-info">
            <div class="name">{{username ? username : '用户名'}}</div>
            <div class="shop">{{shopName ? shopName : '暂无默认店铺'}}</div>
        </div>
    </div>

    <div>
        <div class="me-cell">
            <van-cell title="申请成为肉店管理员" size="large" is-link title-class="cell-title" to="/me/info">
                <img slot="icon" class="cell-icon" alt="phone" src="../assets/image/call.png">
            </van-cell>
        </div>

        <div class="me-cell">
            <van-cell title="新增肉店备案" size="large" is-link title-class="cell-title" to="/me/addShop">
                <img slot="icon" class="cell-icon" alt="records" src="../assets/image/records_manage.png">
            </van-cell>
        </div>

        <div class="me-cell">
            <van-cell title="肉店管理" size="large" is-link title-class="cell-title" to="/me/shopList">
                <img slot="icon" class="cell-icon" alt="records" src="../assets/image/list.png">
            </van-cell>
        </div>

        <!-- <div class="me-cell">
            <van-cell title="我的账单" size="large" is-link title-class="cell-title" to="/me/bills">
                <img slot="icon"  class="cell-icon" alt="bill" src="../assets/image/bill.png" >
            </van-cell>
        </div> -->
        <div class="me-cell" v-if="checked">
            <van-cell title="待审核" size="large" is-link title-class="cell-title" to="/checkList">
                <img slot="icon" class="cell-icon" alt="bill" src="../assets/image/checkman.png">
            </van-cell>
        </div>

        <!-- <div class="me-cell">
            <van-cell title="退出登录" size="large" title-class="loginout"  to="/loginout" @click="removeAll"/>
        </div> -->
    </div>
</div>
</template>

<script>
import localStorage from '../utils/storage';
import cookie from '../utils/cookie';
import https from '../utils/request';
export default {
    data() {
        return {
            meAvatar: '',
            username: '',
            shopName: '',
            checked: false
        };
    },
    mounted() {
        this.getUserInfo();
    },
    methods: {
        removeAll() {
            localStorage.removeAll();
            cookie.set('refresh_token', '');
            cookie.set('token', '');
        },

        getUserInfo() {
            // eslint-disable-next-line no-empty
            if (localStorage.get('userId')) {
                let url = '/api/users';
                let params = {
                    id: localStorage.get('userId')
                };
                https.get(url, {
                    params
                })
                    .then(res => {
                        if (res.status === 200 && res.data && res.data.data) {
                            let account = res.data.data[0];
                            if (account) {
                                this.username = account.nickname;
                                this.meAvatar = account.headImageUrl;
                                if (account.shop) {
                                    this.shopName = account.shop.name;
                                }
                                
                                this.checked = account.roles.some(item => {
                                    return item.authority === 'ROLE_VERIFIER';
                                });
                                let roleList = account.roles.map(item => {
                                    return item.authority;
                                });
                                localStorage.set('roles', JSON.stringify(roleList));
                                localStorage.set('shopId', account.shop ? account.shop.id : '');
                                localStorage.set('status', account.shop ? account.shop.status : '');
                            } else {
                                localStorage.removeAll();
                            }
                        }
                    })
                    .catch(err => {
                        if (err.status !== 401) {
                            this.$toast.fail('请求异常, 请重试！');
                        }
                    });
            }

        }
    }
};
</script>

<style lang="less" scoped>
.bg-top {
    display: flex;
    width: 93%;
    margin: 0 auto;
    height: 200px;
    background: url('https://cdn.xjy0.cn/ticket/trace/me_topbg.png') no-repeat center;
    background-origin: content-box;
    background-clip: content-box;
    background-size: 100% 200px;
    margin-top: 20px;
    align-items: center;

}

.avatar {
    border-radius: 50%;
    padding: 20px;
}

.me-avatar {
    width: 110px;
    height: 110px;
    margin-right: 15rpx;
    border-radius: 50%;
}

.me-info {
    text-align: left;

    & .name {
        color: @white;
        font-size: @font-40px;
    }

    & .shop {
        color: @lightWhite;
    }
}

.cell-icon {
    width: 45px;
    height: 50px;
    margin-right: 20px;
    object-fit: contain;
}

.cell-title {
    text-align: left;
    font-size: @font-34px;
    color: @black;
}

.loginout {
    text-align: center;
    font-size: @font-34px;
    color: @gray;
}

.me-cell {
    margin: 0 auto;
    width: 85%;
    margin-top: 15px;
    padding: 0 20px;
    border-radius: 20px;
    background-color: #fff;
}
</style>
