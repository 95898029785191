<template>
<div class="container">
    <blanket v-if="checkList.length === 0" style="padding-top: 200px"></blanket>
    <van-list v-else v-model="loading" :finished="checkList.length >= total" finished-text="没有更多了" @load="loadMore">
        <ul>
            <li v-for="(item, index) in checkList" :key="index">
                <div class="header">
                    <i><img class="shop-icon" src="../assets/image/shop-icon.png" alt=""></i>
                    <div class="shop-name">{{item.name}}</div>
                    <router-link class="to-check" :to="{path:'/checkDetail', query: {id: item.id}}">去审核>></router-link>
                </div>
                <div class="content">
                    <i><img class="defaulte" :src="item.defaultUrl" alt=""></i>
                    <div class="shop-info">
                        <van-field v-model="item.code" readonly label="经营代码:" label-width=90px style="text-align: left;color: #666" :border="false" />
                        <van-field v-model="item.corp" readonly label="经营法人:" label-width=90px style="text-align: left;color: #666" :border="false" />
                        <van-field v-model="item.phone" readonly label="联系电话:" label-width=90px style="text-align: left;color: #666" :border="false" />
                    </div>
                </div>
            </li>
        </ul>
    </van-list>
</div>
</template>

<script>
import Blanket from '../components/BlanketHolder';
export default {
    components: {
        Blanket
    },
    data() {
        return {
            mobile: '15333545663',
            leagle: 'very-wang',
            code: '005',
            checkList: [],
            loading: false,
            total: 0
        };
    },
    mounted() {
        // hidden tabbar
        // this.hideTabbar();
        this.getCheckList();
    },

    beforeDestroy() {
        // this.showTabbar();
    },

    methods: {
        loadMore() {
            this.getCheckList();
        },
        getCheckList() {
            this.$toast.loading({
                duration: 0,
                message: '数据加载中...',
                forbidClick: true,
                loadingType: 'spinner'
            });
            this.$https.get('/api/shops', {
                params: {
                    start: this.checkList.length,
                    length: 15,
                    status: '未处理'
                }
            })
                .then(res => {
                    this.$toast.clear();
                    console.log('getCkeckList', res.data);
                    if (res.data && res.data.data.length > 0) {
                        this.checkList = this.checkList.concat(res.data.data);
                        this.total = res.data.total;
                    }
                })
                .catch(err => {
                    this.$toast.clear();
                    console.log('catch', err);
                });
        }
    },
};
</script>

<style lang="less" scoped>
.container {
    // height: 100%;
    padding-bottom: 120px;
}

ul {
    li {
        margin: 20px 30px 20px 30px;
        background: #fff;
        border-radius: 20px;

        .header {
            display: flex;
            align-items: center;
            position: relative;

            .shop-icon {
                margin: 18px 24px 18px 30px;
                width: 90px;
            }

            .shop-name {
                font-weight: 600;
                font-size: @font-36px;
            }

            .to-check {
                color: #ff801a;
                position: absolute;
                right: 30px;
            }
        }

        .header::after {
            content: " ";
            position: absolute;
            pointer-events: none;
            box-sizing: border-box;
            -webkit-transform-origin: center;
            transform-origin: center;
            top: auto;
            left: 15px;
            right: 0;
            bottom: 0;
            -webkit-transform: scaleY(.5);
            transform: scaleY(.5);
            border-bottom: 2px solid #ddd;
        }

        .content {
            display: flex;
            align-self: center;

            .defaulte {
                width: 150px;
                height: 180px;
                margin: 30px;
                object-fit: cover;
                border-radius: 10px;
            }

            .shop-info {
                display: flex;
                flex-direction: column;
                margin: 30px 0;
            }
        }
    }
}

.van-cell {
    flex: 0.5 !important;
    font-size: @font-32px  !important;
    padding: 5px 0 !important;
}
</style>
