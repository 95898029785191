<template>
    <div class="ticket-view">
        <!-- 票的类型 -->
        <div class="ticket-title">
            <span>屠宰检疫</span>
        </div>
        <!-- 票的信息 -->
        <div class="ticket-info">
            <van-field v-model="proveData.quarantineNumber" readonly label="检疫证号"  style="text-align: left;color: #666" :border="false"/>
            <van-field v-model="proveData.ticketType" readonly label="产品名称" style="text-align: left;color: #666" :border="false"/>
            <van-field v-model="proveData.numUnit" readonly label="数量" style="text-align: left;color: #666" :border="false"/>
            <van-field v-model="proveData.ownerName" readonly label="货主" style="text-align: left;color: #666" :border="false"/>
            <van-field v-model="proveData.contractor" readonly label="承运人" style="text-align: left;color: #666" :border="false"/>
            <van-field v-model="proveData.modeOfTransport" readonly label="运载方式"  style="text-align: left;color: #666" :border="false"/>
            <van-field v-model="proveData.licensePlateNo" readonly label="运载车牌号" style="text-align: left;color: #666" :border="false"/>
            <van-field v-model="proveData.disinfectionMethod" label-width=150px readonly label="运载工具消毒情况" style="text-align: left;color: #666" :border="false"/>
            <van-field v-model="proveData.quarantinedDate" readonly label="签发日期" style="text-align: left;color: #666" :border="false"/>
            <van-field v-model="proveData.ticketerFullname" readonly label="官方兽医" style="text-align: left;color: #666" :border="false"/>
            <van-field v-model="proveData.startRegion" readonly label="生产单位" type="textarea"  rows="1" autosize  maxlength="50" style="text-align: left;color: #666" :border="false"/>
        </div>
        <div class="arrive">本批产品经检疫合格,应于<span>{{proveData.daysByUpperChinese}}</span>日内到达有效</div>
        <!-- 作废 -->
        <div class="zuofei" v-if="proveData.deleted"><img src="../assets/image/zuofei.png" alt="zuofei"></div>
    </div>
</template>

<script>
export default {
    props: {
        // 证的数据
        proveData: {
            type: Object,
            default: null
        }
    },
};
</script>

<style lang="less" scoped>
.ticket-view {
    background: url(../assets/image/shuiyin.png) no-repeat center;
    background-size: 350px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px 30px 20px;
    text-align: left;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    .ticket-title {
        position: relative;
        padding-bottom:  15px;
        color: #31af01;
    }
    .ticket-title::before {
        display: block;
        content: "";
        width: 9px;
        height: 30px;
        background: linear-gradient(0, #20c854, #7cdf3a);
        position: absolute;
        top: 7px;
        left: 0;
        border-radius: 3px;
    }
    .ticket-title::after {
        content: " ";
        position: absolute;
        pointer-events: none;
        box-sizing: border-box;
        -webkit-transform-origin: center;
        transform-origin: center;
        top: auto;
        left: 15px;
        right: 0;
        bottom: 0;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
        border-bottom: 2px dashed #ddd;
    }
    .ticket-title span {
        margin-left: 20px;
    }
}
.arrive {
    font-size: @font-30px;
    text-align: center;
    color: @mainface;
}

/* 作废 */
.zuofei {
    width: 90px;
    height: 90px;
    position: absolute;
    bottom: 100px;
    right: 100px;
    img {
        width: 120px;
        height: 120px;
    }
}
// vant css
.van-cell {
    font-size: @font-32px  !important;
    background-color: rgba(0,0,0,0);
}
</style>