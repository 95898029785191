<template>
    <div>
        <div class="card" v-for="(item,index) in recordsList" :key="index">
            <div  class="card-top">
                <div class="top-left">
                    <span>
                        <img src="../assets/image/paae.png" alt="">
                    </span>
                    <div class="vaccine">{{item.vaccine}}</div>
                </div>
                <div class="date">{{item.dateOperated}}</div>
            </div>
            <div class="card-bottom">
                <van-field v-model="item.ownerName" readonly label="畜主:" label-width=60px  style="text-align: left;color: #666" :border="false"/>
                <div class="category">
                    <van-field v-model="category" readonly label="畜别:" label-width=60px style="text-align: left;color: #666" :border="false"/>
                    <van-field v-model="item.finals" readonly label="数量:" label-width=60px  style="text-align: left;color: #666" :border="false"/>
                </div>
            </div>
        </div>

        <!-- 若是没有数据则占位 -->
        <blanket v-if="!placeBlanket" style="margin-top:100px;"></blanket>
        <div v-else>
            <!-- 分页加载 -->
            <div class="load-more mr-bottom" v-if="placeBlanket<pageSize"  @click='loadMore' >点击加载更多……</div>
            <div class="load-more mr-bottom" v-else >没有更多了</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Blanket from '../components/BlanketHolder';
import {getDate} from '../utils/utils';
export default {
    components: {
        Blanket
    },
    data() {
        return {
            category: '羊',
            recordsList:[],
            pageSize: 0,
            start: 0
        };
    },
    mounted() {
        this.hideTabbar();
        let id = this.$route.query.ownerId;
        let category = this.$route.query.category;
        if(id && category) {
            this.getRecords(id, category);
        }
    },

    beforeDestroy() {
        this.showTabbar();
    },
    computed: {
        placeBlanket() {
            return this.recordsList.length;
        }
    },

    methods: {
        loadMore() {
            if(this.pageSize - this.recordsList.length > 0) {
                this.start = this.recordsList.length;
                this.getRecords();
            }
        },
        getRecords(id, category) {
            axios.post(`${process.env.VUE_APP_PAAE_BASE_URL}/openapi/record/getRecords`, {
                ownerId: id,
                start: this.start,
                length: 15,
                uuid: 'a6de36a2-fe94-487a-804c-286f9df35896',
                isSecret: 'true',
                category: category
            })
                .then(res => {
                    this.$toast.clear();
                    if (res.data && res.data.success === true && res.data.data) {
                        let result = res.data.data;
                        this.pageSize = res.data.total;
                        this.recordsList = result.map(item => {
                            let date = item.dateOperated.slice(0,10);
                            item.dateOperated = date.replace(/-/g, '/');
                            return item;
                        });
                    } else {
                        this.recordsList = [];
                    }
                })
                .catch(err => {
                    this.$toast.clear();
                    if (err.status !== 401) {
                        this.$toast.fail('异常, 请重试！' + err);
                    }
                });
        }
    },
};
</script>

<style lang="less" scoped>
.card {
    margin: 30px 30px 30px;
    padding: 20px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    background-color: #fff;
    .card-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .top-left {
                flex: 3;
                display: flex;
                align-items: center;
            img {
                width: 70px;
                height: 70px;
                margin-right: 20px;
            }
            .vaccine {
                font-family: '微软雅黑';
                text-align: left;
                font-size: @font-30px;
                font-weight: 600;
            }
        }
        .date {
            font-size: @font-30px;
            color: #ff801a;
        }
    }
    .card-top::after {
        content: " ";
        position: absolute;
        pointer-events: none;
        box-sizing: border-box;
        -webkit-transform-origin: center;
        transform-origin: center;
        top: auto;
        left: 15px;
        right: 0;
        bottom: 0;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
        border-bottom: 2px solid #ddd;
    }
    .card-bottom {
        .category {
            display: flex;
        }
    }
}
.load-more {
    font-size: @font-28px;
    color: #999;
    display: flex;
    justify-content: center;
    padding: 20px;
}
// vant css
.van-cell {
    font-size: @font-32px  !important;
    padding: 5px !important;
}
</style>