<template>
<div class="bg-top">

    <div class="add-shop">
        <div class="title">
            <span class="dot"></span>
            <span>填写备案信息</span>
        </div>
        <van-cell-group>
            <van-field v-model="name" label="肉店名称" placeholder="请输入店铺名称" style="text-align: left" />
            <van-field v-model="code" label="经营代码" maxlength=30  placeholder="请输入肉店经营代码" style="text-align: left" />
            <van-field v-model="legal" label="经营法人" placeholder="请输入经营法人" style="text-align: left" />
            <van-field v-model="call" label="联系电话" maxlength=11 type="number" placeholder="请输入联系电话" style="text-align: left" />
            <!-- <van-field v-model="address" label="店铺位置" disabled placeholder="请选择店铺位置" style="text-align: left" @click="chooseLocation" right-icon="location" is-link/> -->
            <van-cell title="店铺位置" :value="address?address:'请选择店铺位置'" title-class="filter-title" value-class="cell-value" style="text-align: left" is-link @click="chooseLocation">
                <van-icon slot="right-icon" name="location" style="line-height: inherit;" />
            </van-cell>
            <van-field v-model="location" label="详细地址" placeholder="请输入肉店详细地址" style="text-align: left" />
            <van-cell left title="设置为默认店铺" style="text-align: left">
                <van-switch v-model="checked" slot="right-icon" size="30" active-color="#07c160" />
            </van-cell>
        </van-cell-group>

        <!-- 图片上传 -->
        <div class="upload-image">
            <label>照片</label>
            <BaseImagePicker :proveData="localImages" @changed="imageChanged" :symbol="imageComponent" />
        </div>

        <!-- 地图组件 -->
        <div class="map-container" v-if="showMap">
            <Map @location="getLocation"></Map>
        </div>

        <!-- 保存按钮 -->
        <div class="button-view">
            <van-button @click="submitCheck" style="width:85%;" round color="linear-gradient(to right, #20c854, #7edf39)">提交</van-button>
        </div>
    </div>
</div>
</template>

<script>
import Map from '../components/Map';
import BaseImagePicker from "../components/BaseImagePicker";
// import wx from 'weixin-js-sdk';
import https from '../utils/request';
import localStorage from '../utils/storage';
import {
    phoneValidate
} from '../utils/utils';
export default {
    data() {
        return {
            shopId: '',
            name: '',
            call: '',
            legal: '',
            location: '',
            address: '',
            code: '',
            checked: true,
            fileList: [],
            previewList: [],
            latitude: 0, // 纬度，浮点数，范围为90 ~ -90
            longitude: 0, // 经度，浮点数，范围为180 ~ -180。
            localImages: [],
            showMap: false,
            status: ''
        };
    },
    components: {
        BaseImagePicker,
        Map
    },
    computed: {
        // 图片组件使用方式
        imageComponent() {
            if(this.shopId) {
                return false;
            } else {
                return true;
            }
        }
    },
    mounted() {
        this.hideTabbar();
        this.init();
    },

    beforeDestroy() {
        this.showTabbar();
    },

    created() {},

    methods: {
        // getLocation
        getLocation(location) {
            this.showMap = false;
            this.latitude = location.latlng.lat;
            this.longitude = location.latlng.lng;
            this.address = `${location.poiaddress}(${location.poiname})`;
            window.console.log('newshop---location--->', location, this.address, this.latitude, this.longitude);
        },

        init() {
            let query = this.$route.query;
            if (query.hasOwnProperty('shopCode') && query.hasOwnProperty('default')) {
                let shopCode = query.shopCode;
                this.checked = query.default === 'true' || query.default === true ? true : false;
                if (shopCode) {
                    this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: '数据加载中……'
                    });
                    https.get(`/api/shops?id=${shopCode}`)
                        .then(res => {
                            this.$toast.clear();
                            if (res.data.data && res.data.data != 0) {
                                let data = res.data.data[0];
                                this.shopId = data.id;
                                this.name = data.name;
                                this.call = data.phone;
                                this.legal = data.corp;
                                this.location = data.detailedAddress;
                                this.address = data.address;
                                this.code = data.code;
                                // this.checked = data.defaultShop;
                                this.status = data.status;
                                let photos = data.photos ? data.photos.split(";") : [];
                                this.localImages = photos;
                            }
                        })
                        .catch(err => {
                            this.$toast.clear();
                            if (err.status !== 401) {
                                this.$toast.fail('异常, 请重试！' + err);
                            }
                        });
                }
            }
        },

        imageChanged(fileList) {
            window.console.log('图片组件操作------>', fileList);
            this.localImages = fileList;
        },

        // 提交
        submitCheck() {
            let submit = this.formValidation();
            window.console.log('提交前验证', submit);
            if (submit) {
                let userId = localStorage.get('userId');
                if (!userId) {
                    alert("用户信息不能为空，请先登录！");
                    return;
                }

                this.$toast.loading({
                    duration: 0,
                    message: '上传数据中...',
                    forbidClick: true,
                    loadingType: 'spinner'
                });
                let url = '/api/shops';
                let param = {
                    id: this.shopId,
                    photos: this.localImages.join(';'),
                    defaultUrl: this.localImages[0],
                    name: this.name,
                    phone: this.call,
                    corp: this.legal,
                    longitude: this.longitude,
                    latitude: this.latitude,
                    detailedAddress: this.location,
                    address: this.address,
                    code: this.code,
                    defaultShop: this.checked,
                    creator: userId,
                    userId: userId
                };
                if (this.status && this.status === '未通过') {
                    param.status = '未处理';
                }
                https.post(url, JSON.stringify({
                    "data": {
                        "0": param
                    }
                }))
                    .then(res => {
                        // 手动清除 Toast
                        this.$toast.clear();
                        window.console.log('提交返回的数据', res, res.data, res.status);
                        if (res.status == 200 && res.data.data[0]) {
                            this.$toast.success('保存成功！！');
                            // 提交成功跳回首页
                            let timer = setTimeout(() => {
                                this.$router.go(-1);
                                clearTimeout(timer);
                            }, 2000);
                        }
                    }).catch(err => {
                        // 手动清除 Toast
                        this.$toast.clear();
                        if (err.status !== 401) {
                            this.$toast({
                                type: 'fail',
                                message: '服务器异常, 请重试！' + err
                            });
                        }
                    });
            }

        },

        chooseLocation() {
            this.showMap = true;
        },

        // 验证表单
        formValidation() {
            // 肉店名称
            let shopName = this.name;
            if (!shopName) {
                this.$toast.fail('请输入店铺名称');
            }
            // 经营代码
            let shopCode = this.code;
            if (!shopCode) {
                this.$toast.fail('请输入经营代码');
            }
            // 经营法人
            let shopBoss = this.legal;
            if (!shopBoss) {
                this.$toast.fail('请输入经营法人');
            }
            // 电话
            let mobile = phoneValidate(this.call);
            if (!mobile) {
                this.$toast.fail('请输入正确的联系电话');
            }
            // 位置
            let address = this.address;
            if (!address) {
                this.$toast.fail('请选择店铺地址');
            }
            // 照片
            let images = this.localImages.length;
            if (!images) {
                this.$toast.fail('请上传图片');
            }

            return shopName && shopCode && shopBoss && mobile && address && images;
        }
    }
};
</script>

<style lang="less" scoped>
.bg-top {
    height: 360px;
    background-image: linear-gradient(to bottom, #20c854, #7edf39);
    position: relative;
}

.add-shop {
    position: absolute;
    left: 25px;
    right: 25px;
    top: 80px;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 120px;

    & .title {
        text-align: left;
        font-size: @font-32px;
        padding: 10px;

        .dot {
            display: inline-block;
            width: 25px;
            height: 25px;
            background-color: @mainface;
            border-radius: 50%;
            margin-right: 20px;
        }
    }
}

// 上传照片
.upload-image {
    color: #666666;
    text-align: left;
    padding: 20px;
}

.button-view {
    text-align: center;
    margin-top: 20px;
}

// 地图组件
.map-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999992;
    background-color: #fff;
}

// vant css
.van-cell {
    font-size: @font-34px  !important;
    line-height: 50px !important;
    background-color: rgba(255, 255, 255, 0);
}

.filter-title {
    flex: 0.45 !important;
    font-size: @font-34px;
}

.cell-value {
    color: #333;
    font-size: @font-34px;
    text-align: left;
}
</style>
