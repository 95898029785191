import axios from 'axios';
import router from '../router/index';
import storage from './storage';
import cookie from './cookie.js';


axios.defaults.baseURL = process.env.VUE_APP_TRACE_BASE_URL;


const errorHandle = (response) => {
    let status = response.status;
    if (status === 401) {
        let refreshToken = cookie.get('refresh_token') ? JSON.parse(cookie.get('refresh_token')) : '';
        if (refreshToken) {
            return axios.post('/api/oauth/access_token', {
                grant_type: 'refresh_token',
                refresh_token: refreshToken
            }).then(res => {
                if (res && res.data) {
                    storage.set('token', res.data.token_type + ' ' + res.data.access_token);
                    storage.set('userId', JSON.stringify(res.data.userId));
                    storage.set('shopId', JSON.stringify(res.data.shopId));
                    storage.set('roles', JSON.stringify(res.data.roles));
                    cookie.set('roles', JSON.stringify(res.data.roles), 7);
                    cookie.set('refresh_token', JSON.stringify(res.data.refresh_token), 365);
                }
                return instance.request(response.config);
            }).catch(error => {
                if (error) {
                    login();
                }
            });
        } else {
            login();
        }
    }
};

function login() {
    if (navigator.userAgent.toLowerCase().includes('MicroMessenger'.toLowerCase()) && process.env.NODE_ENV !== 'development') {
        let callback = encodeURIComponent('https://trace-mobile.xjy0.cn/#/author');
        let redirect = router.history.current.fullPath;
        console.log(redirect);
        storage.set('redirect', redirect);
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx837fb1a89e11bbc8&redirect_uri=${callback}&response_type=code&scope=snsapi_userinfo&state=${redirect}#wechat_redirect`;
    } else {
        developmentLogin();
    }
    // if (process.env.NODE_ENV === 'development') {
    //     developmentLogin();
    // } else if (process.env.NODE_ENV === 'production') {
    //     // let callback = encodeURIComponent('http://trace.xjy0.cn/#/author');
    //     let callback = encodeURIComponent('http://trace-mobile.xjy0.cn/#/author');
    //     let redirect = router.history.current.fullPath;
    //     storage.set('redirect', redirect);
    //     window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb10bd4939762da65&redirect_uri=${callback}&response_type=code&scope=snsapi_userinfo&state=${redirect}#wechat_redirect`;
    // }
}
function developmentLogin() {
    let params = {
        username: 'admin',
        password: 'shubiao1229'
    };
    axios.post('/api/login', {
        username: 'admin',
        password: 'shubiao1229'
    })
        .then(res => {
            if (res && res.data) {
                storage.set('token', res.data.token_type + ' ' + res.data.access_token);
                storage.set('refresh_token', JSON.stringify(res.data.refresh_token), 365);
                storage.set('userId', JSON.stringify(res.data.userId));
                storage.set('shopId', JSON.stringify(res.data.shopId));
                storage.set('roles', JSON.stringify(res.data.roles), 365);
            }
        })
        .catch(err => {
            console.error("登陆错误message：" + err.message);
        });
}
let instance = axios.create({
    timeout: 1000 * 60
});
instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
instance.interceptors.request.use(
    config => {
        const token = storage.get('token');
        token && (config.headers.Authorization = token);
        return config;
    },
    error => Promise.error(error)
);
// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    res => res.status === 200 ? Promise.resolve(res) : Promise.reject(res),
    // 请求失败
    error => {
        const {
            response
        } = error;
        if (response) {
            if (!response.config.url.includes('/api/login')) {
                return errorHandle(response);
            }
            return Promise.reject(response);
        }
    });

export default instance;
