export default {
    data() {
        return {
            regionCode: ['65', '6532', '652328', '6542']
        };
    },

    async mounted() {
        this.regionCode = await this.getRegionCode();
    },
    methods: {
        getRegionMapPhoto(string) {
            let sixLength = string;
            let twoLength = string.slice(0, 2);
            let fourLength = string.slice(0, 4);
            console.log('region截取--->',sixLength, fourLength, twoLength);
            if (this.regionCode.includes(sixLength)) {
                return sixLength;
            } else if (this.regionCode.includes(fourLength)) {
                return fourLength;
            } else if (this.regionCode.includes(twoLength)){
                return twoLength;
            } else {
                return '65';
            }
        },

        getRegionCode() {
            return this.$https.get('/api/configuration?name=administrativeRegion')
                .then(res => {
                    if(res.data&& res.data.data.length > 0) {
                        const {dValue} = res.data.data[0];
                        return dValue;
                    } else {
                        return ['65', '6532', '652328', '6542'];
                    }
                })
                .catch(err => {
                    console.warn(err);
                });
        }
    },
};