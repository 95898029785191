<template>
<div class="content">
    <!-- 票 -->
    <div class="origin-view">
        <div class="section-title">
            产品票信息
        </div>
        <div class="origin-code">{{ticketCode}}</div>
    </div>

    <!-- boxshadow -->
    <div class="box-scan">
        <div class="section-title other">
            <span class="out" />
            开始编码
        </div>
        <div class="input-view">
            <div class="input">
                <van-field v-model="startCode" type="number" placeholder="请输入开始编码" @focus="focusHideTab" @blur="blurShowTab">
                    <van-icon slot="right-icon" :name="scanIcon" @click="scanQR('startCode')" />
                </van-field>
            </div>
        </div>

        <div class="section-title other">
            <span class="out" />
            结束编码
        </div>
        <div class="input-view">
            <div class="input">
                <van-field v-model="endCode" type="number" placeholder="请输入结束编码" @focus="focusHideTab" @blur="blurShowTab">
                    <van-icon slot="right-icon" :name="scanIcon" @click="scanQR('endCode')" />
                </van-field>
            </div>
            <!-- <div style="width: 200px;height:300px;background-color:yellow;">{{changeCode}}</div> -->
            <!-- 查验按钮 -->
            <div class="button-view">
                <van-button @click="submit" style="width:85%;" round color="linear-gradient(to right, #20c854, #7edf39)">挂票</van-button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import wx from 'weixin-js-sdk';
import https from '../utils/request';
import queryString from 'querystring';
import url from 'url';
import axios from 'axios';
import localStorage from '@/utils/storage';
export default {
    data() {
        return {
            type: 'product',
            ticketCode: '',
            startCode: '',
            endCode: '',
            scanIcon: require('../assets/image/scan.png')
        };
    },

    created() {
        // this.setWxConfig();
        this.iniTicket();
    },
    mounted() {
        // this.hideTabbar();
    },

    beforeDestroy() {
        // this.showTabbar();
    },

    computed: {},

    methods: {
        iniTicket() {
            this.ticketCode = this.$route.query.ticket;
        },
        submit() {
            if (!this.startCode) {
                this.$toast({
                    type: 'fail',
                    message: '开始编码不能为空！！'
                });
                return;
            }
            if (this.endCode && this.startCode) {
                if (!((parseInt(this.endCode) - parseInt(this.startCode) > 0) && (parseInt(this.endCode) - parseInt(this.startCode) <= 50))) {
                    this.$toast.fail('请检查开始编码和结束编码是否正确,一次挂票不得超过50张！');
                    return;
                }
            }
            // 是否
            const shopId = localStorage.get('shopId');
            const userId = localStorage.get('userId');
            if (!shopId || !userId) {
                this.$toast.fail('暂未获取到当前店铺信息！');
                return;
            }
            this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '数据加载中……'
            });
            let url = '/api/hangTicketRecords';
            https.post(url, JSON.stringify({
                "data": {
                    "0": {
                        ticket: this.ticketCode,
                        startCode: this.startCode,
                        endCode: this.endCode,
                        shop: shopId,
                        operator: userId
                    }
                }
            }))
                .then(res => {
                    this.$toast.clear();
                    if (res.data) {
                        if (res.data.fieldErrors) {
                            this.$toast.fail(res.data.fieldErrors[0].status);
                            return;
                        }
                        this.$toast.success('挂票成功!!');
                        // 提交成功跳回首页
                        let timer = setTimeout(() => {
                            this.$router.go(-1);
                            clearTimeout(timer);
                        }, 2000);
                    }
                }).catch(err => {
                    this.$toast.clear();
                    window.console.log(err);
                    if (err.status !== 401) {
                        this.$toast.fail('挂票失败, 请重试！' + err);
                    }
                });

        },
        scanQR(index) {
            wx.scanQRCode({
                needResult: 1,
                scanType: ['qrCode'],
                success: async (res) => {
                    let result = res.resultStr;
                    // result = 'http://xjy0.cn/p/ZRbUBj';
                    // result = 'http://xjy0.cn/p?n=56788988';
                    if (result.includes('//xjy0.cn/p?n=')) {
                        let ticket = url.parse(result).query;
                        let query = queryString.parse(ticket);
                        if (query &&query.n) {
                            this[index] = query.n;
                            window.console.log('//xjy0.cn/p?n=',this[index]);
                        } 
                        return '';
                    } else if (result.includes('//xjy0.cn/p')) {
                        this[index] = await this.shortCodeChangeLong(result);
                    }   else {
                        this[index] = result;
                    }
                },
                fail: (err) => {
                    window.console.log(err);
                    this.$toast.fail('请重试点击扫码！！');
                    this.setWxConfig();
                }
            });
        },

        // 追溯码 短码换长码
        shortCodeChangeLong(qrUrl) {
            if (!qrUrl) return;
            let that = this;
            return new Promise(function (resolve, reject) {
                axios.get(`${process.env.VUE_APP_CHANGECODE_BASE_URL}/productNumber/get?miniUrl=${qrUrl}`)
                    .then(res => {
                        if (res.data && res.data.success) {
                            resolve(res.data.number);
                        } else {
                            resolve('');
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        that.$toast.fail('换票失败, 请重试！');
                        resolve('');
                    });
            });
        },


        // tabbar 的处理
        focusHideTab() {
            // hidden tabbar
            this.hideTabbar();
        },

        // 失去焦点
        blurShowTab() {
            this.showTabbar();
        },
    }

};
</script>

<style lang="less" scoped>
.origin-view {
    margin: 30px 25px;
    border-left: 12px solid #feaf26;
    display: flex;
    align-items: center;
    font-size: @font-34px;

    .origin-code {
        color: @mainface;
        font-weight: 600;
        font-size: @font-40px;
    }
}

.section-title {
    color: @black;
    font-weight: 600;
    margin: 0 20px;
}

.box-scan {
    // display: flex;
    margin: 30px 20px;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    align-items: center;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);

    .other {
        text-align: left;
        display: flex;
        align-items: center;
    }

    .input-view {
        // position: fixed;
        padding: 0 10px;

        & .input {
            padding: 5px;
            margin: 40px 0;
            border-radius: 80px;
            background-color: #f7f7f7;
            // box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
        }
    }
}

.out {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 10px solid @mainface;
    margin-right: 20px;
}

.button-view {
    text-align: center;
    margin: 90px 0 50px;
}

// vant css
.van-cell {
    font-size: @font-36px  !important;
    background-color: rgba(255, 255, 255, 0);
}

.van-icon {
    font-size: 50px !important;
}
</style>
