<template>
<div class="container">
    <!-- 查出对应的证 -->
    <div style="padding: 0 10px;">
        <PronductA v-if="ticketType === 'productA'" :proveData="ticketInfo"></PronductA>
        <PronductB v-if="ticketType === 'productB'" :proveData="ticketInfo"></PronductB>
    </div>
    <div class="button-view">
        <van-button @click="submitCheck" style="width:85%;" round color="linear-gradient(to right, #20c854, #7edf39)">到店确认</van-button>
    </div>
</div>
</template>

<script>
import PronductA from '../components/Product-A';
import PronductB from '../components/Product-B';
import localStorage from '../utils/storage';
import {
    getDateTime,
    getWxLocation
} from '@/utils/utils';
export default {
    components: {
        PronductA,
        PronductB
    },

    data() {
        return {
            ticketInfo: {},
            date: '',
            location: ''
        };
    },

    created() {

    },

    async mounted() {
        this.location = await getWxLocation();
        window.console.log('地理位置');
        // hidden tabbar
        this.hideTabbar();
        // 票的详情
        let data = this.$store.state.checkedData;
        this.ticketInfo = data;
        this.date = getDateTime();
    },

    beforeDestroy() {
        this.showTabbar();
    },

    computed: {
        ticketType() {
            if (this.ticketInfo && this.ticketInfo.type) {
                return this.$store.state.ticketType + this.ticketInfo.type;
            } else {
                return '';
            }
        }
    },

    methods: {
        // 当前票是否到过店
        arriveShop(ticket) {
            let that = this;
            return new Promise(function (resolve, reject) {
                that.$https.get('/api/arrives?', {
                    params: {quarantineNumber: ticket}
                })
                    .then(res => {
                        // reject(res.data);
                        resolve(res.data);
                    })
                    .catch(err => {
                        resolve({total: 0});
                    });
            });
        },
        // 提交
        async submitCheck() {
            const arrive = await this.arriveShop(this.ticketInfo.quarantineNumber);
            window.console.log('arrive', arrive);
            // if (!arrive) return;
            if (arrive && arrive.total <= 0) {
                const shopId = localStorage.get('shopId');
                const status = localStorage.get('status');
                if(shopId && status === '已通过' && !this.ticketInfo.deleted) {
                    this.$toast.loading({
                        duration: 0,
                        message: '数据上传中...',
                        forbidClick: true,
                        loadingType: 'spinner'
                    });

                    let url = '/api/arrives';
                    this.$https.post(url, JSON.stringify({
                        "data": {
                            "0": {
                                shop: shopId,
                                quarantineNumber: this.ticketInfo.quarantineNumber,
                                type: this.ticketInfo.type,
                                product: this.ticketInfo.animal.name + '/' +  this.ticketInfo.product.label,
                                number: this.ticketInfo.number + this.ticketInfo.unit,
                                arriveDate: this.date,
                                longitude: this.location.longitude,
                                latitude: this.location.latitude
                            }
                        }
                    }))
                        .then(res => {
                            this.$toast.clear();
                            if (res.data) {
                                if (res.data.fieldErrors) {
                                    this.$toast.fail(res.data.fieldErrors[0].status);
                                    return;
                                }
                                this.$toast.success('到店成功!!');
                                // 提交成功跳回首页
                                let timer = setTimeout(() => {
                                    this.$router.go(-1);
                                    clearTimeout(timer);
                                }, 2000);
                            }
                        }).catch(err => {
                            this.$toast.clear();
                            window.console.log(err);
                            if (err.status !== 401) {
                                this.$toast.fail('到店失败, 请重试！' + err);
                            }
                        });
                } else if (!shopId || shopId === 'null') {
                    this.$toast.fail('暂无归属肉店！！无法确认到店！！');
                    return;
                } else {
                    this.$toast.fail('肉店未通过审核或该票已经作废，无法确认到店！！');
                }
            } else {
                this.$toast.fail('该票已经到过店,请勿重复到店!');
            }

        }
    }
};
</script>

<style lang="less" scoped>
.container {
    padding-bottom: 100px;
}
.button-view {
    text-align: center;
    margin-top: 100px;
}
</style>
