<script>
import axios from '../utils/request';
import url from 'url';
import queryString from 'querystring';
import storage from '../utils/storage.js';
import cookie from '../utils/cookie.js';

export default {
    created() {
        let query = url.parse(window.location.href).query;
        query = queryString.parse(query);
        if (query.code) {
            axios.post('/api/loginwechat', JSON.stringify(query))
                .then(async res => {
                    if (res && res.data) {
                        window.console.info("从后台得到的token="+res.data.token_type + ' ' + res.data.access_token);
                        storage.set('token', res.data.token_type + ' ' + res.data.access_token);
                        storage.set('userId', JSON.stringify(res.data.userId));
                        storage.set('roles', JSON.stringify(res.data.roles));
                        cookie.set('roles', JSON.stringify(res.data.roles), 7);
                        cookie.set('refresh_token', JSON.stringify(res.data.refresh_token), 365);
                        let userInfo = await this.getUserInfo(res.data.userId);
                        window.console.log('登录拉去用户信息', userInfo);
                    }

                    if (storage.get('redirect') && storage.get('redirect') !== 'undefined') {
                        this.$router.replace(storage.get('redirect'));
                    } else {
                        this.$router.replace('/');
                    }
                })
                .catch(err => {
                    this.$toast.fail('登录中报错,', err);
                    window.console.log('登录中报错,', err);
                });
        }
    },

    methods: {
        getUserInfo(userId) {
            let that = this;
            return new Promise(function(resolve,reject){
                if(userId){
                    axios.get(`/api/users?id=${userId}`)
                        .then(res => {
                            if (res.status === 200 && res.data && res.data.data) {
                                let account = res.data.data[0];
                                storage.set('shopId', account.shop ? account.shop.id: '');
                                storage.set('status', account.shop ? account.shop.status: '');
                                resolve(res.data);
                            }
                        })
                        .catch(err => {
                            if (err.status !== 401) {
                                that.$toast.fail('登录失败, 请重试！' + err);
                                reject(err);
                            }
                        });
                }
            });
        }
    }
};
</script>
