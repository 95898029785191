<template >
<div class="container">
    <div class="cell" v-for="(item,index) in shopList" :key="index" @click="SelectedCell(item)">
        <img class="cell-icon" :src="item.defaultUrl ? item.defaultUrl: '../assets/image/record_list.png'" alt="icon">
        <div class="cell-content">
            <!-- 肉店 -->
            <div class="cell-first">
                <div class="cell-code">
                    <span>{{item.name}}</span>
                    <span class="tag" :class="{'invalidate': !item.validate}">{{item.status}}</span>
                    <span class="defaulte" v-if="item.default">默认</span>
                </div>
                <img class="delete" src="../assets/image/delete.png" alt="delete" @click.stop="deleteShop(item)">
            </div>
            <!--经营代码  -->
            <div class="cell-row">
                <span class="row-title">经营代码</span>
                <span class="row-content">{{item.code}}</span>
            </div>
            <!--法人  -->
            <div class="cell-row">
                <span class="row-title">经营法人</span>
                <span class="row-content">{{item.corp}}</span>
            </div>
        </div>
    </div>
    <!-- 若是没有数据则占位 -->
    <blanket v-if="!placeBlanket" style="margin-top:150px;"></blanket>
</div>
</template>

<script>
import Blanket from '../components/BlanketHolder';
import https from '../utils/request';
import localStorage from '../utils/storage';
import {
    Dialog
} from 'vant';
export default {
    components: {
        Blanket
    },
    data() {
        return {
            shopList: [],
            userId: ''
        };
    },
    computed: {
        placeBlanket() {
            return this.shopList.length;
        }
    },
    mounted() {
        this.userId = localStorage.get('userId');
        // hidden tabbar
        // this.hideTabbar();
        this.getShopList();
    },

    beforeDestroy() {
        // this.showTabbar();
    },

    methods: {
        // 删除
        deleteShop(item) {
            Dialog.confirm({
                message: '确认删除该店铺？'
            })
                .then(() => {
                    if (item.id && this.userId) {
                        this.$toast.loading({
                            duration: 0,
                            message: '店铺删除中...',
                            forbidClick: true,
                            loadingType: 'spinner'
                        });
                        this.$https.post('/api/userShop/deleteShop', {
                            userId: this.userId,
                            shopId: item.id
                        })
                            .then(res => {
                                this.$toast.clear();
                                if (res.status === 200) {
                                    this.$toast.success(`${item.name}店铺已删除`);
                                    this.getShopList();
                                }
                            })
                            .catch(err => {
                                if (err.status !== 401) {
                                    this.$toast.fail(`删除店铺失败, 请重试！${err}`);
                                }
                            });
                    }
                })
                .catch(() => {

                });
        },

        SelectedCell(record) {
            this.$router.push({
                path: '/me/addShop',
                query: {
                    shopCode: record.id,
                    default: record.default
                }
            });
        },

        getShopList() {
            if (this.userId) {
                this.$toast.loading({
                    duration: 0,
                    message: '数据加载中...',
                    forbidClick: true,
                    loadingType: 'spinner'
                });

                https.get(`/api/userShops?userId=${this.userId}`)
                    .then(res => {
                        this.$toast.clear();
                        if (res.status === 200 && res.data && res.data.data) {
                            this.shopList = res.data.data.map(item => {
                                let items = item.shop;
                                // 默认否
                                items.default = item.defaultShop;
                                if (items.status === '已通过') {
                                    items.status = '已验证';
                                    items.validate = 1;
                                } else {
                                    items.validate = 0;
                                }
                                return items;
                            });
                        } else {
                            this.shopList = [];
                        }
                    })
                    .catch(err => {
                        this.$toast.clear();
                        if (err.status !== 401) {
                            this.$toast.fail('异常, 请重试！' + err);
                        }
                    });
            }

        }
    }
};
</script>

<style lang="less" scoped>
.container {
    padding-bottom: 120px;
}
.cell {
    display: flex;
    margin: 30px 20px;
    padding: 15px;
    border-radius: 20px;
    background-color: #fff;
    align-items: center;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);

    & .cell-icon {
        width: 150px;
        height: 180px;
        margin-right: 20px;
        border-radius: 10px;
        // object-fit: contain;
    }

    & .cell-content {
        flex: 5;
        text-align: left;
        color: @gray;
        position: relative;

        & .cell-first {
            display: flex;
            align-items: center;

            & .cell-code {
                flex: 3;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                color: @black;
                font-size: @font-36px;
            }

            .delete {
                width: 35px;
            }
        }

        & .cell-row {
            margin-top: 10px;

            .row-title {
                display: inline-block;
                width: 150px;
            }

            .row-content {
                color: @black;
            }
        }
    }
}

.defaulte {
    min-width: 50px;
    margin-left: 10px;
    color: #f47124;
    font-size: @font-28px;
    background-color: #fcdaa6;
    padding: 0 10px;
    border-radius: 20px;
}

.tag {
    min-width: 80px;
    font-size: @font-28px;
    padding: 0 10px;
    border-radius: 20px;
    color: @white;
    background-color: @mainface;
    margin-left: 20px;
}

.invalidate {
    background-color: #f47124;
}
</style>
