<template>
<div class="container">
    <!-- 搜索框 -->
    <van-sticky :offset-top="0">
        <van-search v-model="keyWords" shape="round" show-action="show-action" background="#51c325" placeholder="请输入搜索关键词" @search="onSearch" @focus="serachInput">
            <div slot="action" class="search" @click="onSearch">搜索</div>
        </van-search>
    </van-sticky>
    <!-- cell -->
    <!-- <refresh @on-bottom="handleScroll" :refresh="isMore"> -->
    <div class="table-view">
        <div class="cell" v-for="(item,index) in shopList" :key="index" @click="SelectedCell(item)">
            <img class="cell-icon" :src="item.defaultUrl ? item.defaultUrl: '../assets/image/record_list.png'" alt="icon">
            <div class="cell-content">
                <!-- 肉店 -->
                <div class="cell-row">
                    <span class="row-title">店铺名称</span>
                    <span class="row-content">{{item.name}}</span>
                </div>
                <!--经营代码 -->
                <div class="cell-row">
                    <span class="row-title">经营代码</span>
                    <span class="row-content">{{item.code}}</span>
                </div>
                <!--法人 -->
                <div class="cell-row">
                    <span class="row-title">法人</span>
                    <span class="row-content">{{item.corp}}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- </refresh> -->
    <!-- 若是没有数据则占位 -->
    <blanket v-if="!placeBlanket" style="margin-top:200px;"></blanket>
    <div v-else>
        <!-- 分页加载 -->
        <div class="load-more mr-bottom" v-if="shopList.length<pageSize" @click='loadMore'>点击加载更多……</div>
        <div class="load-more mr-bottom" v-else>没有更多了</div>
    </div>
</div>
</template>

<script>
import Blanket from '../components/BlanketHolder';
import https from '../utils/request';
import localStorage from '../utils/storage';
export default {
    components: {
        Blanket
    },
    data() {
        return {
            shopList: [],
            code: '',
            name: '',
            call: '',
            keyWords: '',
            pageSize: 0
        };
    },
    computed: {
        placeBlanket() {
            return this.shopList.length;
        },
        isMore() {
            return ((this.pageSize - this.shopList.length > 0) ? false : true);
        },
    },

    mounted() {
        // hidden tabbar
        // this.hideTabbar();
        this.getShopList();
    },

    beforeDestroy() {
        // this.showTabbar();
    },

    methods: {
        // 聚焦清空数据
        serachInput() {
            // this.shopList = [];
        },

        // 分页
        loadMore() {
            console.log('已经到底部了');
            if (this.pageSize - this.shopList.length > 0) {
                this.getShopList();
            }
        },

        // 搜索
        onSearch() {
            this.pageSize = 0;
            this.shopList = [];
            this.getShopList();
        },

        SelectedCell(record) {
            // 更新info
            let newInfo = Object.assign({}, this.$store.state.info);
            newInfo.code = record.code;
            this.$store.commit('SET_INFO', newInfo);
            this.$router.go(-1);
        },

        getShopList() {
            this.$toast.loading({
                duration: 0,
                message: '数据加载中...',
                forbidClick: true,
                loadingType: 'spinner'
            });
            https.get('/api/shops', {
                params: {
                    status: '已通过',
                    start: this.shopList.length,
                    length: 10,
                    keyWord: this.keyWords
                }
            })
                .then(res => {
                    this.$toast.clear();
                    if (res.status === 200 && res.data && res.data.data) {
                        let array = res.data.data;
                        this.pageSize = res.data.total;
                        if (array.length > 0) {
                            this.shopList = this.shopList.concat(array);
                        } else {
                            this.shopList = [];
                        }
                    }
                })
                .catch(err => {
                    this.$toast.clear();
                    if (err.status !== 401) {
                        this.$toast.fail('异常, 请重试！' + err);
                    }
                });
        }

    }
};
</script>

<style lang="less" scoped>
.container {
    padding-bottom: 120px;
}

.cell {
    display: flex;
    margin: 15px 20px;
    padding: 15px;
    border-radius: 20px;
    background-color: #fff;
    align-items: center;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);

    & .cell-icon {
        width: 150px;
        height: 180px;
        margin-right: 20px;
        border-radius: 10px;
        object-fit: cover;
    }

    & .cell-content {
        flex: 5;
        text-align: left;
        color: @gray;
        position: relative;

        & .cell-first {
            display: flex;
            align-items: center;

            & .cell-code {
                flex: 3;
                display: flex;
                align-items: center;
                color: @black;
                font-size: @font-36px;
            }
        }

        & .cell-row {
            margin-top: 10px;

            .row-title {
                display: inline-block;
                width: 150px;
            }

            .row-content {
                color: @black;
            }
        }
    }
}

.van-cell {
    font-size: @font-34px;
}

.search {
    font-size: @font-34px;
    color: @white;
}

.load-more {
    font-size: @font-28px;
    color: #999;
    display: flex;
    justify-content: center;
    padding: 20px;
}
</style>
